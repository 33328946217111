import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ActionDropdown = ({ onSelectRequest, cloneRequest, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="outlined"
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onSelectRequest(row)}>VIEW</MenuItem>
        <MenuItem onClick={() => cloneRequest(row)}>CLONE</MenuItem>
      </Menu>
    </div>
  );
};

export default ActionDropdown;
