import RedactLogin from "."
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const RedactLoginComponent = ({companyName}) => {
    return (  
            <div>
                        <RedactLogin company_name={companyName} />

                            <>
                                <Grid container wrap="nowrap">
                                    {Array.from(new Array(2)).map((item, index) => (
                                        <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                                            <Skeleton variant="rectangular" width={"90%"} height={320} />
                                        </Box>
                                    ))}
                                </Grid>
                                <Grid container wrap="nowrap">
                                    {Array.from(new Array(2)).map((item, index) => (
                                        <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                                            <Skeleton variant="rectangular" width={"90%"} height={320} />
                                        </Box>
                                    ))}
                                </Grid>
                            </>

        </div>
    );
}
export default RedactLoginComponent;
 