import * as React  from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import FirstStep from "./Steps/FirstStep/FirstStep";
import SecondStep from "./Steps/SecondStep/SecondStep";
import ThirdStep from "./Steps/ThirdStep/ThirdStep";
import { useEffect } from "react";
import { API_BASE } from "../../constants";
import axios from "axios";
const steps = [
  "Select companies to merge",
  "Provide target company",
  "Review and Submit",
];

export default function StepperComponent(mergeId) {

  const [formData, setFormData] = React.useState({
    entityNameEnglish: '',
    entityNameLegal: '',
    country: '',
    latitude: '',
    longitude: '',
    address: '',
    entity_type: '',
    origin_company_id: '',
    investigationStatus: 'Not Applicable',
    internalNotes: 'Merging Locations to create single Location',
    investigationNotes: 'Merging Locations to create single Location',
  });

  const handleFormDataChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };


  const [activeStep, setActiveStep] = React.useState(0);

  const [selectedCompanies , setSelectedCompanies] = React.useState([])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(()=> {
    const fetchSelections = async (id) => {
      try {
        const response = await axios.get(
          `${API_BASE}/getMergeRequestSelections?id=${id}`
        );
        setSelectedCompanies([...selectedCompanies, ...response.data?.results])
        const entity_details = response.data?.entity_dic;
        console.log(entity_details)
        for (var key in entity_details) {
          console.log(key, entity_details[key])
              handleFormDataChange(key, entity_details[key]);
        }
        console.log(formData)
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
    console.log(mergeId, mergeId !== undefined)
    if(mergeId.mergeId !== undefined) {
      fetchSelections(mergeId.mergeId)
    }
  },[]);
  useEffect(()=> {
    console.log(formData , "ksk")
  },[formData])
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <>
          {activeStep === 0 && (
            <FirstStep
              selectedCompanies={selectedCompanies}
              setSelectedCompanies={setSelectedCompanies}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              mergeId = {mergeId}
            ></FirstStep>
          )}
          {activeStep === 1 && (
            <SecondStep
              formData = {formData}
              handleFormDataChange = {handleFormDataChange}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedCompanies={selectedCompanies}
            ></SecondStep>
          )}
          {activeStep === 2 && (
            <ThirdStep
              formData = {formData}
              selectedCompanies = {selectedCompanies}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
            ></ThirdStep>
          )}
        </>
      )}
    </Box>
  );
}
