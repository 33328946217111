import { createSlice } from "@reduxjs/toolkit";

import { getHomepageDetailsThunk } from "../../services/homepage-thunk";

const initialState = {
    details: {},
    loading: true
}

const homepageSlice = createSlice({
    name: 'details',
    initialState,
    extraReducers: {
        [getHomepageDetailsThunk.pending]:
            (state) => {
                state.loading = true
                state.details = []
            },
        [getHomepageDetailsThunk.fulfilled]:
            (state, { payload }) => {
                state.loading = false
                state.details = payload
            },
        [getHomepageDetailsThunk.rejected]:
            (state) => {
                state.loading = false
            }

    }

});

export default homepageSlice.reducer;