import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import "./index.css";

import { getMergeDetails } from "../../../../services/merge-service";

const steps = [
  "Select companies to merge",
  "Provide target company",
  "Review and Submit",
];

const SecondStep = ({
  formData,
  handleFormDataChange,
  handleNext,
  handleBack,
  activeStep,
  selectedCompanies
}) => {
  const {
    entityNameEnglish,
    entityNameLegal,
    country,
    country_name,
    origin_company_id,
    origin_company_name,
    latitude,
    longitude,
    address,
    entity_type,
    investigationStatus,
    internalNotes,
    investigationNotes,
    suspicious
  } = formData;

  const [countries, setCountries] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);
  const [investigationTypes, setInvestigationTypes] = useState([]);
  const [originCompanies, setOriginCompanies] = useState([]);
  const [suspiciousFlag, setSuspiciousFlag] = useState(suspicious);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  const uniqueOriginCompanyIdsSet = new Set();

// Filter and map to get unique origin companies
  const uniqueOriginCompanies = selectedCompanies
    .map(company => company.origin_company)
    .filter(originCompany => {
      const isUnique = !uniqueOriginCompanyIdsSet.has(originCompany.id);
      uniqueOriginCompanyIdsSet.add(originCompany.id);
      return isUnique;
    });
    setOriginCompanies(uniqueOriginCompanies);
    console.log(uniqueOriginCompanies,"ogc");
  },[selectedCompanies]);
    useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getMergeDetails();
       
        console.log("Merge details:", response.data);
        // const countryNames = response.data.countries.map(
        //   (country) => country.name
        // );
        // const entityTypesNames = response.data.entity_type.map(
        //   (entityType) => entityType.name
        // );
        var investigationTypesNames = response.data.investigation_type.map(
          (investigationType) => investigationType.status
        );
        setCountries(response.data.countries);
        setEntityTypes(response.data.entity_type);
        // investigationTypesNames = [...investigationTypesNames]
        setInvestigationTypes(investigationTypesNames);
      } catch (error) {
        console.error("Error fetching merge details:", error);
       
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="first-steploader-container">
        <CircularProgress />
      </div>
    );
  }
    const isFormDataValid = () => {
      const canSkip = []
      for (const key in formData) {
          const value = formData[key];

          // Check for non-empty values for string fields
          if (typeof value === 'string' && value.trim() === '') {
              // alert(`Please fill in the required field: ${key}`);
              return false; // If any string field is empty, return false
          }

          // Check for existence of values for other data types
          if (typeof value !== 'string' && (value === undefined || value === null)) {
              // alert(`Please fill in the required field: ${key}`);
              return false; // If any non-string field is empty, return false
          }
      }
      return true; // All fields have valid values
  };
  const handleCoordinateChange = (fieldName, inputValue) => {
    // Remove non-numeric characters and keep only digits, dots, or hyphens
    const sanitizedValue = inputValue.replace(/[^0-9.-]/g, '');

    if (/^-?\d*\.?\d*$/.test(sanitizedValue)) {
        handleFormDataChange(fieldName, sanitizedValue);
    } else {
        alert(`Invalid input for ${fieldName}`);
    }
  };
  return (
    <div className="second-step-container">
      <div className="second-step-input-container">
        <div className="second-step-input-container-top">
          <TextField
            className="second-step-input-container-top-element second-step-entity-name-english"
            id="outlined-basic"
            label="Entity name english"
            variant="outlined"
            value={entityNameEnglish}
            onChange={(e) =>
              handleFormDataChange("entityNameEnglish", e.target.value)
            }
          />

          <TextField
            className="second-step-input-container-top-element second-step-entity-name-legal"
            id="outlined-basic"
            label="Entity name Legal"
            variant="outlined"
            value={entityNameLegal}
            onChange={(e) =>
              handleFormDataChange("entityNameLegal", e.target.value)
            }
          />

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Country"
              value={country}
              onChange={(e) => {
                handleFormDataChange("country_name", countries.find((c) => c.id === e.target.value)?.name);
                handleFormDataChange("country", e.target.value);  
                }}
            >

              {countries.map((country) => (
                <MenuItem value={country.id}>{country.name}</MenuItem>
              ))}

              {/* <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }} className="second-step-input-container-top-element">
            <InputLabel id="demo-simple-select-label">Group with</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Group With"
              value={origin_company_id}
              onChange={(e) => {
                handleFormDataChange("origin_company_id", e.target.value);
                handleFormDataChange("origin_company_name", originCompanies.find((c)=> c.id === e.target.value)?.company_name_english)
                }}
            >
              {originCompanies.map((company) => (
                <MenuItem value={company.id}>{company.company_name_english} - {company.id}</MenuItem>
              ))}

              {/* <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          <TextField
              className="second-step-input-container-top-element second-step-entity-latitude"
              id="outlined-basic"
              label="Latitude"
              variant="outlined"
              value={latitude}
              onChange={(e) => handleCoordinateChange("latitude", e.target.value)}
          />

          <TextField
              className="second-step-input-container-top-element second-step-entity-longitude"
              id="outlined-basic"
              label="Longitude"
              variant="outlined"
              value={longitude}
              onChange={(e) => handleCoordinateChange("longitude", e.target.value)}
          />
        {/* </div> */}

        {/* <div className="second-step-input-container-bottom"> */}
         

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">Entity Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Entity Type"
              value={entity_type}
              onChange={(e) =>
                handleFormDataChange("entity_type", e.target.value)
              }
            >
              {entityTypes.map((entityType) => (
                <MenuItem value={entityType.id}>{entityType.name}</MenuItem>
              ))}

              {/* <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">
              Investigation Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Investigation Status"
              value={investigationStatus}
              onChange={(e) =>
                handleFormDataChange("investigationStatus", e.target.value)
              }
            >
              {investigationTypes.map((status) => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}

              {/* <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>

          <TextField
            className="second-step-investigation-notes"
            id="outlined-basic"
            label="Investigation Notes"
            variant="outlined"
            value={internalNotes}
            onChange={(e) =>
              handleFormDataChange("internalNotes", e.target.value)
            }
          />

          <TextField
            className="second-step-internal-notes"
            id="outlined-basic"
            label="Internal notes"
            variant="outlined"
            value={investigationNotes}
            onChange={(e) =>
              handleFormDataChange("investigationNotes", e.target.value)
            }
          />
           <TextField
            multiline
            aria-label="text box to enter your concern"
            rows={10}
            label="Address"
            maxRows={10}
            value={address}
            onChange={(e) => handleFormDataChange("address", e.target.value)}
          />
          <FormControl  sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Suspicious</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="suspicious-flag-select"
                  value={suspiciousFlag?.toString()}
                  onChange={(e) => {
                    setSuspiciousFlag( e.target.value === "true" ? true : false)
                    handleFormDataChange("suspicious", e.target.value === "true")}
                  }
              >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
              </Select>
          </FormControl>
        </div>
       
      </div>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleNext} disabled={!isFormDataValid()}>
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </div>
  );
};

export default SecondStep;
