import React from "react";
import { DataGrid, GridToolbar,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarDensitySelector,GridToolbarFilterButton } from '@mui/x-data-grid';



const ThirdStepTable = ({companies}) =>{
    const columns = [
        
        {
            field: 'company_name_english', headerName: 'Name',
            flex:1,
            headerClassName: "tier-supplier-table-header-modal",
            // maxWidth: 200,
            minHeight: 200,
           
            renderHeader: () => (
                <div className='tier-supplier-table-header-modal'>
                    {'Name'}
                </div>
            ),
            renderCell: (params) => (
              <a href={`http://sc-investigation.rc.northeastern.edu:8090/admin/homepage/scdh_companylocationresolved/${params.row.id}`} target="_blank" rel="noopener noreferrer">
                {params.value}
              </a>
            ),
        },
        {
            field: 'full_address', headerName: 'full address', 
            flex:2,
            headerClassName: "tier-supplier-table-header-modal",
            // maxWidth: 800,
            
            renderHeader: () => (
                <div className='tier-supplier-table-header-modal'>
                    {'Full address'}
                </div>
            ),
            
        }

    ];

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </GridToolbarContainer>
        );
      }



    return (
        <div>
            <DataGrid
                  // owHeight={95}
                  getRowHeight={() => 'auto'} 
                  rows={companies.map((item, index) => ({
                    id: index + 1, // Add a numerical ID
                    ...item, // Spread the existing properties
                  }))}
                  
                  columns={columns}
                  components={{ Toolbar: CustomToolbar }}
                  autoHeight="true"
                  density="compact"
                  initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
        </div>
    )
}

export default ThirdStepTable