import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants"; 


export const get_all_details = async (company) => {

    console.log(company)
   
    const response = await axiosRequest.get(`${API_BASE}/getAllCompanyInfo/?search=${company}`);
    
    console.log(response)
    return response;
}