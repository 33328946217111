import React, { useState, useEffect } from "react";
import Papa from 'papaparse';
import "./index.css";
import axios from "axios";
import { API_BASE } from "../../../../constants";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const CSVParser = ({ selectedCompanies , setSelectedCompanies }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      Papa.parse(file, {
        header: true,
        complete: async (result) => {
          const idColumn = result.data.map((row) => row.id);
          const filteredIds = idColumn.filter((id) => id !== undefined && id !== null); 
          console.log(filteredIds); 
          try {
            const response = await axios.post(
                `${API_BASE}/mergesearchbyids`
                , {
              ids: filteredIds,
            });
            console.log(response)
            setSelectedCompanies([...selectedCompanies, ...response.data?.results])
          } catch (error) {
            console.error('Error making API call:', error);
          }
        },
      });
    };
    const handleClearFile = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
          fileInput.value = '';
          setSelectedFile(null);
        }
      };
    return (
        <div className="file-container-style">
      {/* Hidden file input */}
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        className="file-input-style"
        id="fileInput"
      />

      {/* Visible button to trigger file input */}
      <label htmlFor="fileInput">
        <Button variant="contained" component="span">
          Upload File
        </Button>
      </label>

      {/* Clear button */}
      <IconButton
        color="secondary"
        onClick={handleClearFile}
        className="clear-button-style"
        disabled={!selectedFile}
      >
        <DeleteIcon />
      </IconButton>
    </div>
    );
};

export default CSVParser;


