import { useRef, useState } from "react";
import L, { map } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-polylinedecorator";

import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';


import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import React, { useEffect } from "react";

import "./index.css";
import { min } from "lodash";


const getColor = (level) => {
  if(level===0) {
    return 'red';
  } else if(level===1) {
    return 'orange';
  } else if(level===2) {
    return 'yellow';
  } else {
    return 'green';
  }
};
const plot_line_color = (from_id, to_id, suspicious_level_dictionary) => {
    
  var from_level = -1;
  var to_level = -1;
  var color_tobe_filled = "steelblue";
  for (
    var i = 0;
    i < Object.keys(suspicious_level_dictionary).length;
    i++
  ) {
    var hasFromCompany = suspicious_level_dictionary[i].some(function (
      company
    ) {
      return (
        from_id === company
        // ||from_id.includes(company_name)
      );
    });
    var hasToCompany = suspicious_level_dictionary[i].some(function (
      company
    ) {
      return (
        to_id === company // || to_id.includes(company_name)
      );
    });
    if (hasFromCompany && from_level===-1) {
      from_level = i;
    }
    if (hasToCompany && to_level===-1) {
      to_level = i;
    }
    if (from_level !== -1 && to_level !== -1) {
      var k = Math.min(from_level, to_level);
      if (k === 0) {
        color_tobe_filled = "red";
      } else if (k === 1) {
        color_tobe_filled = "orange";
      } else if (k === 2) {
        color_tobe_filled = "yellow";
      }
      break;
    }
  }
  return [from_level, to_level, color_tobe_filled];
}

const SupplierMapComponent = ({
  details,
  company_name,
  is_customer,
  selectedTierFilter,
  selectedSuppliers
}) => {
  const handleZoomOut = () => {
    if (locMapRef.current) {
      // Check if the map instance exists
      locMapRef.current.setView([0, 0], 2.4); // Set the view to the initial position
    }
  };
  const [loading, setLoading] = useState(false);
  const locMapRef = useRef(null);
  const markerGroupRef = useRef(null);
  const lineGroupRef = useRef(null);
  const decoratorGroupRef = useRef(null);
 
  const plotSupplerMap = (from_map, displayDetails) => {
    var tiers = displayDetails?.tiers;
   
    const markerGroup =  markerGroupRef.current; 
    const lineGroup = lineGroupRef.current;
    const decoratorGroup = decoratorGroupRef.current;
    for (let tierIndex = 1; tierIndex <= tiers?.length; tierIndex++) {
      const tier = tiers[tierIndex-1];
      var relationships = is_customer
        ? tier.to_relations
        : tier.from_relations;
        if(tierIndex>parseInt(selectedTierFilter, 10)) {
          break
        }
      if(relationships.length > 100) {
        relationships = relationships.sort((a,b) => {
          const aSuspicious = a.from_company__suspicious || a.to_company__suspicious;
          const bSuspicious = b.from_company__suspicious || b.to_company__suspicious;

          return bSuspicious - aSuspicious;
        })
        relationships = relationships.slice(0,100);
      }
      relationships.forEach((relationship) => {

        const startLatLng = [
          relationship.from_company__latitude !== null
            ? relationship.from_company__latitude
            : 0,
          relationship.from_company__longitude !== null
            ? relationship.from_company__longitude
            : 0,
        ];

        const endLatLng = [
          relationship.to_company__latitude !== null
            ? relationship.to_company__latitude
            : 0,
          relationship.to_company__longitude !== null
            ? relationship.to_company__longitude
            : 0,
        ];

        const color =
          relationship.to_company__suspicious === false ||
          relationship.from_compay__suspicious === false || relationship.from_compay__suspicious == null || relationship.to_compay__suspicious == null
            ? "steelblue"
            : "Orange";
        const [from_level, to_level, _] = plot_line_color(relationship.from_company__id, relationship.to_company__id, details.sus_relations)
        const line = L.polyline([startLatLng, endLatLng], {
          color,
        }).addTo(lineGroup);


        line.bindPopup(`<div><strong>From Company: </strong><a href="${relationship.from_company__id}" target="_blank">${relationship.from_company__company_name_english}</a></div><div><strong>To Company:</strong> <a href="${relationship.to_company__id}" target="_blank">${relationship.to_company__company_name_english}</a> </div>`);
        const marker = L.circleMarker(startLatLng, {
          radius: relationship.from_company__suspicious === false ? 8 : 10,
          fillColor:
            (relationship.from_company__suspicious === false || relationship.from_company__suspicious === null ) ? "green" : "red",
          color:
            (relationship.from_company__suspicious === false || relationship.from_company__suspicious === null) ? "green" : "red",
          weight: 1,
          opacity: 1,
          fillOpacity: 1,
        }).addTo(markerGroup);

        marker.bindPopup(`<div class="company-name"><a href="${relationship.from_company__id}" target="_blank">${relationship.from_company__company_name_english}</a></div>
                    <div class="popup-address">Address: ${relationship.from_company__full_address}</div>`);
      

        const minLevel = (from_level !== -1 && to_level !== -1) ? Math.min(from_level, to_level) : Infinity;

        if (minLevel !== Infinity && minLevel <3) {
          line.setStyle({ color: getColor(minLevel) });
        } else {
          line.setStyle({ color: "steelblue" });
        }
        if(selectedSuppliers.length>0) {
          const decorator = L.polylineDecorator(line, {
              patterns: [
              { offset: '50%', repeat: 0, symbol: L.Symbol.arrowHead({ pixelSize: 5, polygon: false, pathOptions: { stroke: true, color: 'black' } }) }

                  ]
          }).addTo(decoratorGroup); 
        } 
        if (
          relationship.from_company__suspicious === false &&
          relationship.from_company__company_name_english.includes(company_name)
        ) {
          marker.setStyle({ color: "black", fillColor: "black" });

        } else if(from_level !== -1) {
          const color = getColor(from_level)
          marker.setStyle({ color: color, fillColor: color });
        } else if(
          relationship.from_company__suspicious === false &&
          selectedSuppliers.length > 0 &&
          selectedSuppliers.includes(relationship.from_company__company_name_english)
        ) {
          marker.setStyle({ color: "purple", fillColor: "purple" });
        }

        const marker2 = L.circleMarker(endLatLng, {
          radius: relationship.to_company__suspicious === false ? 8 : 10,
          fillColor:
            (relationship.to_company__suspicious === false || relationship.to_company__suspicious === null) ? "green" : "red",
          color:
            (relationship.to_company__suspicious === false || relationship.to_company__suspicious === null) ? "green" : "red",
          weight: 1,
          opacity: 1,
          fillOpacity: 1,
        }).addTo(markerGroup);
        marker2.clicked = 0;
        marker2.bindPopup(`<div class="company-name"><a href="${relationship.to_company__id}" target="_blank">${relationship.to_company__company_name_english}</a></div>
                    <div class="popup-address">Address: ${relationship.to_company__full_address}</div>`);
        if (
          relationship.to_company__suspicious === false &&
          relationship.to_company__company_name_english.includes(company_name)
        ) {
          marker2.setStyle({ color: "black", fillColor: "black" });
        } else if (to_level !== -1) {
          const color = getColor(to_level)
          marker2.setStyle({ color: color, fillColor: color });
        } else if(
          relationship.to_company__suspicious === false &&
          selectedSuppliers.length > 0 &&
          selectedSuppliers.includes(relationship.to_company__company_name_english)
        ) {
          marker2.setStyle({ color: "purple", fillColor: "purple" });

        } else {
        }

      });
    };
    setLoading(false);
    markerGroupRef.current.addTo(from_map);
    lineGroupRef.current.addTo(from_map);
    decoratorGroupRef.current.addTo(from_map);
  };

  
  useEffect(() => {
    setLoading(true);
    let locMap = locMapRef.current;
    if (!locMap) {
      locMap = L.map('supplier-map-unique-id').setView(
          [details?.company?.latitude || 0, details?.company?.longitude || 0],
          12
      );

      L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
          {
              attribution:
                  'Map tiles by <a href="https://carto.com/">Carto</a>, ' +
                  'data by <a href="https://openstreetmap.org/">OpenStreetMap</a>',
              maxZoom: 18,
          }
      ).addTo(locMap);

      const maxBounds = [
          [-90, -180], // Southwest corner (latitude, longitude)
          [90, 180], // Northeast corner (latitude, longitude)
      ];

      locMap.setMaxBounds(maxBounds);

      locMap.dragging.enable();
      locMapRef.current = locMap;
      }
     
      if (markerGroupRef.current) {
        markerGroupRef.current.clearLayers();
      } else {
          markerGroupRef.current = L.layerGroup(); // No addTo here
      }
      if(decoratorGroupRef.current) {
        decoratorGroupRef.current.clearLayers();
      } else {
        decoratorGroupRef.current = L.layerGroup();
      }
      if (lineGroupRef.current) {
        lineGroupRef.current.clearLayers();
      } else {
          lineGroupRef.current = L.layerGroup(); // No addTo here
      }

      plotSupplerMap(locMap, details);
      
      locMap.on("zoom", function () {
        const currentZoom = locMap.getZoom();

        const slimLineZoomThreshold = 1;

        locMap.eachLayer(function (layer) {
          if (layer instanceof L.Polyline) {
            if (currentZoom < slimLineZoomThreshold) {
              layer.setStyle({ weight: 0 }); // Adjust line width when zoomed out
            } else {
              layer.setStyle({ weight: 1 }); // Adjust line width when zoomed in
            }
          }
        });
      });
      
    
  }, [selectedTierFilter, details]);

  return (
    <>
      <div className="map-component-container">
      {loading ? <CircularProgress />
          :<div></div>}
          <div className="map-reset-section">
                
                <Button
                  className="map-reset-zoom-button"
                  variant="outlined"
                  aria-label="zoom out map to original size button"
                  startIcon={<ZoomOutMapIcon />}
                  onClick={handleZoomOut}
                >
                  Reset Zoom
                </Button>
               
               
        </div>
        
        {
          !loading && (
            <div
              className="supplier-map-plot"
              id="supplier-map-unique-id"
              style={{ height: "400px" }}
            ></div> 
          )
        }  
      </div>
    </>
  );
};

export default SupplierMapComponent;
