import React,{useState} from "react";
import "./index.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import ThirdStepTable from "./third-step-table";
import ThirdStepTargetCompanyDisplay from "./third-step-target-company-display";
import { axiosRequest } from "../../../../services/utils/axios";
import { API_BASE } from "../../../../constants";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CopyDialog from "./CopyDialog";


const ThirdStep = ({ formData , selectedCompanies , activeStep, handleBack, handleNext }) => {

  const [testStatus, setTestStatus] = useState('')
  const initialRemoveDanglingOriginCompany = false;
  const initialAllowSuspiciousLocations = false;
  const initialAllowRelatedLocations = false;
  const initialAllowRelatedCompanies = true;
  const [loading, setLoading] = useState(false);

  // State variables
  const [removeDanglingOriginCompany, setRemoveDanglingOriginCompany] = useState(initialRemoveDanglingOriginCompany);
  const [allowSuspiciousLocations, setAllowSuspiciousLocations] = useState(initialAllowSuspiciousLocations);
  const [allowRelatedLocations, setAllowRelatedLocations] = useState(initialAllowRelatedLocations);
  const [allowRelatedCompanies, setAllowRelatedCompanies] = useState(initialAllowRelatedCompanies);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorResponse, setErrorResponse] = useState('');
  const handleTest = () => {
    const testMerge = async () => {
      try {
        const requestData = {
            'locations_to_merge' : selectedCompanies.map(company => company.id),
            'company_name_english': formData.entityNameEnglish ?? '',
            'full_address': formData.address ?? '',
            'street_address': formData.street_address ?? '',
            'postal_code': formData.postal_code ?? '',
            'city': formData.city ?? '',
            'latitude': formData.latitude ?? null,
            'longitude': formData.longitude ?? null,
            'transactions_involved': formData.transactions_involved ?? 0,
            'suspicious': formData.suspicious ?? false,
            'origin_company_id': formData.origin_company_id ?? null,
            'state': formData.state ?? '',
            'company_name_legal': formData.entityNameLegal ?? '',
            'investigation_status_id': formData.investigation_status_id ?? '1',
            'source': formData.source ?? 'mergeOperation',
            'investigation_notes': formData.investigationNotes ?? '',
            'country_id': formData.country ?? null,
            'entity_type_id': formData.entity_type ?? null,
            'geocode_level': formData.geocode_level ?? '',
            'resolution_date': formData.resolution_date ?? null,
            'resolution_source': formData.resolution_source ?? '',
            'geocode_source': formData.geocode_source ?? '',
            'matched_address': formData.matched_address ?? '',
            'char_country': formData.char_country ?? '',
            'internal_notes': formData.internalNotes ?? '',
            'remove_dangling_origin_company': removeDanglingOriginCompany,
            'allow_suspicious_locations': allowSuspiciousLocations ,
            'allow_related_locations':  allowRelatedLocations,
            'allow_related_companies': allowRelatedCompanies 
        };
        console.log(requestData)
        setLoading(true);
        const response = await axiosRequest.post(`${API_BASE}/testmerge/`, requestData);
        console.log(response)
        setLoading(false);
        if(response.status === 200) {
          setTestStatus('completed');
          alert("merge is possible");
        } else {
          setTestStatus('');
          alert(response.data?.data)
        }

        // setTestStatus('');
      } catch (error) {
        setTestStatus('');
        setLoading(false);
        setErrorResponse(error.response?.data?.data);
        setOpen(true);
        // alert(error.response?.data?.data);
        console.error('Error fetching requests:', error.response?.data);
        return false
      }
    };
    setTestStatus('inProgress');
    testMerge()
    
  };     
  const handleSubmitRequest = () => {
    const submitRequest = async () => {
      try {
        const requestData = {
            'locations_to_merge' : selectedCompanies.map(company => company.id),
            'company_name_english': formData.entityNameEnglish ?? '',
            'full_address': formData.address ?? '',
            'street_address': formData.street_address ?? '',
            'postal_code': formData.postal_code ?? '',
            'city': formData.city ?? '',
            'latitude': formData.latitude ?? null,
            'longitude': formData.longitude ?? null,
            'transactions_involved': formData.transactions_involved ?? 0,
            'suspicious': formData.suspicious ?? false,
            'origin_company_id': formData.origin_company_id ?? null,
            'state': formData.state ?? '',
            'company_name_legal': formData.entityNameLegal ?? '',
            'investigation_status_id': formData.investigation_status_id ?? null,
            'source': formData.source ?? 'mergeOperation',
            'investigation_notes': formData.investigationNotes ?? '',
            'country_id': formData.country ?? null,
            'entity_type_id': formData.entity_type ?? null,
            'geocode_level': formData.geocode_level ?? '',
            'resolution_date': formData.resolution_date ?? null,
            'resolution_source': formData.resolution_source ?? '',
            'geocode_source': formData.geocode_source ?? '',
            'matched_address': formData.matched_address ?? '',
            'char_country': formData.char_country ?? '',
            'internal_notes': formData.internalNotes ?? '',
            'remove_dangling_origin_company': removeDanglingOriginCompany,
            'allow_suspicious_locations': allowSuspiciousLocations ,
            'allow_related_locations':  allowRelatedLocations,
            'allow_related_companies': allowRelatedCompanies 
        };
        const response = await axiosRequest.post(`${API_BASE}/submitMergeRequest/`, requestData);
        console.log(response)
        if(response.status === 200) {
          // alert("finished");
          // window.location.reload();
          navigate("/mergerequest");
        } else {
          alert(response.data?.data)
        }
      } catch (error) {
        console.error('Error fetching requests:', error);
        alert(error)
        return false
      }
    };
    submitRequest()
    
  };
  const handleFinish = () => {
    handleSubmitRequest()
    console.log(selectedCompanies)
  };
  const handleCheckboxChange = (event, setStateFunction) => {
    setStateFunction(event.target.checked);
  };
  const handleDownload = () => {
    // Convert selected companies to CSV content

    let csvContent = "id,Name,address,latitude,longitude,suspicious,group_with,country\n";
    csvContent+=`-,"${formData.entityNameEnglish}","${formData.address}","${formData.latitude}","${formData.longitude}","${formData.suspicious}","${formData.origin_company_id}","${formData.country_name}"\n`

    csvContent = csvContent +selectedCompanies.map(company =>
       `${company.id},"${company.company_name_english}","${company.full_address}","","","","",""`
       ).join("\n");

    // Create a Blob containing the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'selected_companies.csv';
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
   
    <div className="third-step-container">
    {open ?
      <CopyDialog open={open} onClose={handleCloseDialog} errorResponse={errorResponse} />
      : <></>
    }
     {loading ? (
      <div style={{textAlign: "center", marginBottom: "3rem"}}>
        <CircularProgress />
        <p><strong>Testing...</strong></p>
      </div>)
      : (<></>)}
      <div className="third-step-data-container">
        <div className="third-step-merge-companies">
            <ThirdStepTable companies = {selectedCompanies}></ThirdStepTable>
        </div>
        <div className="third-step-target-company">
            <ThirdStepTargetCompanyDisplay formData={formData}></ThirdStepTargetCompanyDisplay>
        </div>

      </div>
      <div style={{marginBottom: '1rem',marginTop: '1rem', marginLeft: '40%'}}>
          <button onClick={handleDownload}>Download Selected Companies</button>
      </div>
      <div>
        <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={removeDanglingOriginCompany}
              onChange={(event) => handleCheckboxChange(event, setRemoveDanglingOriginCompany)}
            />
          }
          label="Remove Dangling Company Groups"
        />
        <Tooltip
          title="Remove Orphaned Company Groups"
          placement="right-start"
          arrow
          >
          <InfoIcon></InfoIcon>
        </Tooltip>
        </div>
        <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowSuspiciousLocations}
              onChange={(event) => handleCheckboxChange(event, setAllowSuspiciousLocations)}
            />
          }
          label="Allow Suspicious Locations"
        />
          <Tooltip
          title="If there are suscious locations selected to merge knowingly, please confirm"
          placement="right-start"
          arrow
          >
          <InfoIcon></InfoIcon>
        </Tooltip>
        </div>
        
        <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowRelatedLocations}
              onChange={(event) => handleCheckboxChange(event, setAllowRelatedLocations)}
            />
          }
          label="Allow Related Entities"
        />
        <Tooltip
          title="While merging if we encounter a company location is making a transaction to itself, do you want to continue?"
          placement="right-start"
          arrow
          >
          <InfoIcon></InfoIcon>
        </Tooltip>
        </div>
        
        <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowRelatedCompanies}
              onChange={(event) => handleCheckboxChange(event, setAllowRelatedCompanies)}
            />
          }
          label="Allow Related Company Groups"
        />
        <Tooltip
          title="While merging if we encounter a company group relationship with itself, do you want to continue?"
          placement="right-start"
          arrow
          >
          <InfoIcon></InfoIcon>
        </Tooltip>
        </div>
        

        {/* Additional JSX for your component */}
      </div>
      
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleTest}>Test</Button>
        <Button onClick={handleFinish} disabled={testStatus !== 'completed'} >Submit</Button>
      </Box>
    </div>
  );
};

export default ThirdStep;
