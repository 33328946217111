import './index.css'
import { useEffect, useRef, useState } from "react";
import L, { map } from "leaflet";
import SupplierMapFilterComponent from '../SupplierMapFIlterComponent';
import SupplierMapComponent from '.';
import { Tooltip } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";

import './index.css';
import ColorLegend from './ColorLegend';

const filterByName = (allRelations,supplierIntersect, is_customer) => {
    return allRelations.filter(relation => {
        if(is_customer) {
            return supplierIntersect.includes(relation.to_company__company_name_english)
        } else {
            return supplierIntersect.includes(relation.from_company__company_name_english)
        }
    });
};
const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }
  
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepCopy(value)])
    );
};

const filterRelations = (is_customer,company_name, suppliers, allSuppliersData) => {
    var allRelations = []
    const suppliersData = deepCopy(allSuppliersData);
    suppliersData.data.tiers.forEach((tier) => {
        if(is_customer) {
            allRelations.push(...tier.to_relations);
        } else {
            allRelations.push(...tier.from_relations);
        }
    })
    var visitedSuppliers = new Set(suppliers);
    let supplierIntersect = suppliers  // Initialize with your initial supplier_intersect data
    let filteredRelation_ids = [];  // Initialize with your array of objects
    while (supplierIntersect.length > 0) {

        let cr = filterByName(allRelations, supplierIntersect, is_customer);
        filteredRelation_ids.push(...cr.map(rel => rel.id));
        var newSuppliers = new Set();
        if(is_customer) {
            newSuppliers = new Set(cr.map(relation => relation.from_company__company_name_english));
        } else {
            newSuppliers = new Set(cr.map(relation => relation.to_company__company_name_english));
        }
        newSuppliers = new Set([...newSuppliers].filter(supplier => !visitedSuppliers.has(supplier)));

        newSuppliers.delete(company_name);

        supplierIntersect = [...newSuppliers];
        visitedSuppliers = new Set([...visitedSuppliers, ...newSuppliers]);
    }

    var tierCount = suppliersData.data.tiers.length;
    for(var i=0;i<tierCount;i++) {
        if(is_customer) {
            suppliersData.data.tiers[i].to_relations = suppliersData.data.tiers[i].to_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))
        } else {
            suppliersData.data.tiers[i].from_relations = suppliersData.data.tiers[i].from_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))
        }
    }
    return suppliersData;
}
const plot_line_color = (from_id, to_id, suspicious_level_dictionary) => {
    
    var from_level = -1;
    var to_level = -1;
    for (
      var i = 0;
      i < Object.keys(suspicious_level_dictionary).length;
      i++
    ) {
      var hasFromCompany = suspicious_level_dictionary[i].some(function (
        company
      ) {
        return (
          from_id === company
        );
      });
      var hasToCompany = suspicious_level_dictionary[i].some(function (
        company
      ) {
        return (
          to_id === company
        );
      });
      if (hasFromCompany && from_level===-1) {
        from_level = i;
      }
      if (hasToCompany && to_level===-1) {
        to_level = i;
      }
      
    }
    return [from_level, to_level];
  }
  const filterSuspiciousData = (details, is_customer, sus_relations) => {
    const detailsCopy = JSON.parse(JSON.stringify(details));
    for(var i=0; i<detailsCopy.tiers?.length; i++) {
      var tier =  detailsCopy.tiers[i]
      var relationships = is_customer
        ? tier.to_relations
        : tier.from_relations;
      let newr = relationships.filter((relation) => {
          var from_id = relation.from_company__id;
          var to_id = relation.to_company__id;
          const [from_level, to_level] = plot_line_color(from_id, to_id, sus_relations);
          if (from_level === -1 || to_level === -1) {
            return false;
          } else {
            return true;
          }
      });
      console.log(newr, "tier")
      if(is_customer) {
        tier.to_relations = newr
      } else {
        tier.from_relations = newr
      }
      detailsCopy.tiers[i] = tier
      console.log( detailsCopy.tiers[i], "tier")
    }
    console.log(detailsCopy);
    return detailsCopy;
  }
const SupplierMapEntryComponent = ({allSuppliersData, tiersName, company_name, is_customer }) => {
    const [suppliersLoading, setSuppliersLoading] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [selectedTierFilter, setSelectedTierFilter] = useState("3");
    const [suppliersData, setSuppliersData] = useState(null)   
    const [limitedSupplierData, setLimitedSupplierData] = useState(null); 

    const fetchSuppliers = (filterQuery) => {
        try {
            setSuppliersLoading(true);
            setSelectedSuppliers(filterQuery.suppliers);          
            if(filterQuery.suppliers.length>0) {
                const filteredData = filterRelations(is_customer,company_name, filterQuery.suppliers ,allSuppliersData)
                if(filterQuery.is_sus) {
                    filteredData.data = filterSuspiciousData(allSuppliersData?.details, is_customer,allSuppliersData?.data?.sus_relations);
                }
                setSuppliersData(filteredData);
            } else {
                const filteredData = deepCopy(limitedSupplierData);
                console.log(allSuppliersData,"popop")
                if(filterQuery.is_sus) {
                    const k = filterSuspiciousData(allSuppliersData?.data, is_customer,allSuppliersData?.data?.sus_relations);
                    filteredData.data = k;
                    console.log(k,"kova")
                }
                console.log(limitedSupplierData, filteredData, "popop")
                setSuppliersData(filteredData);
            }
            setSelectedTierFilter(filterQuery.tiers);
            setSuppliersLoading(false);
        }
        catch (error) {
            console.log(error)
            setSuppliersLoading(false)
        }
    }
    useEffect(() => {
        const suppliersData = deepCopy(allSuppliersData);

        var tiers = suppliersData?.data?.tiers;
        var prev = []
        console.log(tiers,"popop")
        for (let tierIndex = 1; tierIndex <= tiers?.length; tierIndex++) {
            const tier = tiers[tierIndex-1];
            var relationships = is_customer
              ? tier.to_relations
              : tier.from_relations;
              if(tierIndex>parseInt(selectedTierFilter, 10)) {
                break
              }
            if(relationships.length > 100) {
              relationships = relationships.sort((a,b) => {
                const aSuspicious = a.from_company__suspicious || a.to_company__suspicious;
                const bSuspicious = b.from_company__suspicious || b.to_company__suspicious;
      
                return bSuspicious - aSuspicious;
              })
              if (prev.length > 0) {
                relationships = relationships.filter((r) => is_customer ? prev.includes(r.to_company__id) : prev.includes(r.from_company__id))
              } else {
                relationships = relationships.slice(0,100);
              }
              prev = [...prev, ...relationships.map((r) => is_customer ? r.to_company__id : r.from_company__id)]
            }
            console.log(relationships, "popop ", tierIndex)
            if(is_customer) {
                suppliersData.data.tiers[tierIndex-1].to_relations = relationships;
            } else {
                suppliersData.data.tiers[tierIndex-1].from_relations = relationships;
                console.log(suppliersData.data.tiers[tierIndex-1].from_relations, "popop ", tierIndex)
            }
          }
          console.log(suppliersData,"popop");
          setLimitedSupplierData(suppliersData);
    }, [allSuppliersData]);
    useEffect(()=> {
        const filterQuery = {
            company: company_name,
            product: "ALL",
            suppliers: [],
            tiers: "3",
            is_sus: false
    };
        fetchSuppliers(filterQuery);
    },[limitedSupplierData]);
    const handleFilterSelection = (appliedFilter) => {
        const filterQuery = {
            company: company_name,
            product: "ALL",
            suppliers: appliedFilter.suppliers,
            tiers: appliedFilter.tier,
            is_sus: appliedFilter.is_sus
        }
        console.log(filterQuery)
        fetchSuppliers(filterQuery);
    }
    return (
        <div>
            {!suppliersLoading && ((!is_customer && suppliersData?.data?.has_suppliers) ||(is_customer && suppliersData?.data?.has_customers) )  && (
                    <div className='supplier-data-container' >
                        <div className='supplier-map'>

                            <div className='supplier-map-header-container'>
                                <div className='supplier-map-header-text'>
                                    {is_customer ? "Customers" : "Suppliers"} on Map {" "}
                                    <Tooltip
                                    title="This map is based off of relationships between entities, and does not necessarily represent the flow of a specific product between tiers"
                                    placement="right-start"
                                    arrow
                                    >
                                    <InfoIcon></InfoIcon>
                                    </Tooltip>
                                </div>

                                
                            </div>
                            <div className='supplier-map-component-filter-container'>
                                    <SupplierMapFilterComponent
                                        numberOfTiers={suppliersData?.data.tiers.length}
                                        countries={[...new Set(suppliersData?.data.tiers.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.supplier_countries.split(",")], []))]}
                                        tierNames={tiersName}
                                        handleFilterSelection={handleFilterSelection}
                                        is_customer = {is_customer}
                                    ></SupplierMapFilterComponent>
                            </div>
                            <SupplierMapComponent details={suppliersData?.data} company_name={company_name} is_customer={is_customer}  selectedTierFilter={selectedTierFilter} selectedSuppliers={selectedSuppliers}/>
                            <div style={{padding: '3rem'}}>
                              <ColorLegend />
                            </div>
                            
                        </div>
                    </div>
            )}
        </div>
    );
};

export default SupplierMapEntryComponent;

          // if(!prevSupplierFiltersRef.current || (filterQuery && filterQuery.tiers > suppliersData?.tiers?.length)) {
            //     tiersLoaded.current = filterQuery.tiers
            // }
            // const tiersOnlyChanged =
            // prevSupplierFiltersRef.current &&
            // prevSupplierFiltersRef.current.tiers !== filterQuery.tiers && filterQuery.tiers < tiersLoaded.current &&
            // JSON.stringify(
            //     { ...prevSupplierFiltersRef.current, tiers: undefined },
            // ) === JSON.stringify(
            //     { ...filterQuery, tiers: undefined },
            // );
            
            // const suppliersOnlyChanged =
            // prevSupplierFiltersRef.current &&
            // prevSupplierFiltersRef.current.suppliers !== filterQuery.suppliers &&
            // JSON.stringify(
            //     { ...prevSupplierFiltersRef.current, tiers: undefined, suppliers: undefined },
            // ) === JSON.stringify(
            //     { ...filterQuery, tiers: undefined, suppliers: undefined },
            // );
            // if(!prevSupplierFiltersRef.current) {
            //     prevSupplierFiltersRef.current = { ...filterQuery, tiers: 3 };
            // } else {
            //     prevSupplierFiltersRef.current = filterQuery;
            // }