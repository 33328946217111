import { createSlice } from "@reduxjs/toolkit";

import { getCustomerDataThunk } from "../../services/company-details-thunks";

const initialState = {
    company_details: {},
    loading: true
}

const customerDetailsPageSlice = createSlice({
    name: 'customer_details',
    initialState,
    extraReducers: {
        [getCustomerDataThunk.pending]:
            (state) => {
                state.loading = true
                state.customer_details = {}
            },
        [getCustomerDataThunk.fulfilled]:
            (state, { payload },action) => {
                state.loading = false
                state.customer_details = payload
            },
            
        [getCustomerDataThunk.rejected]:
            (state) => {
                state.loading = false
            },

    }

});

export default customerDetailsPageSlice.reducer;