import CookieConsent from "react-cookie-consent";
import React, { useState, useEffect } from "react";

const CookiePopup = () => {

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
      const hasConsentCookie = document.cookie.includes("supplytraceCookieConsent=true");
      setShowPopup(!hasConsentCookie);
    }, []);
    const handleDecline = () => {
        // Handle decline action, e.g., redirect to a privacy policy page
        setShowPopup(false);
      };
    return (
        <>
        {showPopup && (
          <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              cookieName="trace2CookieConsent"
              style={{ backgroundColor: "#B3DAF1", color: '#000000', fontSize: "23px"}}
              declineButtonStyle={{ color: "#ffffff", backgroundColor: "#FF0000", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer",  fontWeight: 'bold' }}
              buttonStyle={{ backgroundColor: '#006ebe', color: "#F9F9F9", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontWeight: 'bold'  }}
              expires={1}
              enableDeclineButton={true}
              onDecline={handleDecline}
          >
            <span>We use digital cookies to make Supply Trace more user-friendly and to understand how people are utilizing our site.</span>
          
          </CookieConsent>
        )}
      </>
    );
};

export default CookiePopup