import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import './index.css';
import { axiosRequest } from '../../../services/utils/axios';
import { API_BASE } from '../../../constants';
import { CircularProgress } from '@mui/material';

const ApproveDenyAcrion = ({selectedRequest}) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpen = (action) => {
    setAction(action);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApprove = (reason) => {
    const approveAction = async () => {
      try {
        console.log(selectedRequest);
        setLoading(true);
        const response = await axiosRequest.put(`${API_BASE}/approveMergeRequest/`, {'request_id' : selectedRequest.merge_request?.id,'reason': reason});
        setLoading(false);
        if(response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data?.data ?? "not permitted")
        }
      } catch (error) {
        if (error.response && error.response.data) {
          alert(error.response.data.data ?? "An error occurred");
        } else {
          alert(error);
        }
        return false;
      }
    };
    approveAction()
    
  };     
  const handleDeny = (reason) => {
    const denyAction = async () => {
      try {
        console.log(selectedRequest);
        const response = await axiosRequest.put(`${API_BASE}/denyMergeRequest/`, {'request_id' : selectedRequest.merge_request?.id,'reason': reason});
        if(response.status === 200) {
          window.location.reload();
        } else {
          alert(response.data?.error_message ?? "not permitted");
        }
      } catch (error) {
        console.error('Error fetching requests:', error);
        alert(error);
        return false
      }
    };
    denyAction()
    
  };  

  const handleConfirmationOk = () => {

    console.log(`Confirmed ${action} with reason:`, reason);
    if(action === 'Approve') {
      handleApprove(reason);
    } else {
      handleDeny(reason);
    }
    setOpen(false);
  };

  const handleConfirmationCancel = () => {
    // Close the confirmation dialog
    setOpen(false);
  };


  return (
    <>
    <div>
    {loading ? (
          <div style={{textAlign: "center", marginBottom: "3rem"}}>
            <CircularProgress />
            <p><strong>Approving...</strong></p>
          </div>)
          : (<></>)}
    </div>
    <div className='actions'>
        <Button variant="contained" color="secondary" onClick={() => handleOpen('Deny')}>
            Deny
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleOpen('Approve')}>
           {
            selectedRequest.merge_status === 'failed' ? "Re-Process" : "Approve"
           }
        </Button>
    </div>
      

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {`Are you sure you want to ${action}?`}
        </DialogTitle>

        <DialogContent>
          {/* Text box for collecting reason */}
          <TextField
            label="Reason"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button disabled={!reason.trim()} variant="contained" color="primary" onClick={handleConfirmationOk}>
            OK
          </Button>
          <Button variant="contained" color="secondary" onClick={handleConfirmationCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ApproveDenyAcrion;
