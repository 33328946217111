import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AdvanceSearchResultsComponent from "./AdvanceSearchResultsComponent.js";
import { ADVANCE_SEARCH_HEADING,ADVANCE_SEARCH_RISK_LEGEND } from "../../constants/index.js";
import "./response.json";

import "./index.css";
import { useParams } from "react-router-dom";

const AdvancedSearchScreen = () => {
  const { query } = useParams();

  const [searchMethod, setSearchMethod] = useState("contains");
  const [searchText, setSearchText] = useState(""); // State to store the text from TextField

  const [companyToSearch, setCompanyToSearch] = useState("");
  const [companyToSearchMethod, setCompanyToSearchMethod] = useState("contains");
  useEffect(() => {
    if(query!==undefined && query.length > 0) {
      setSearchText(query)
      setCompanyToSearch(query)
    }
  },[])
  const handleChange = (event) => {
    setCompanyToSearchMethod(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };

  const handleSearch = (event) => {
    console.log(companyToSearch);
    setSearchText(companyToSearch);
    setSearchMethod(companyToSearchMethod);
  };

  return (
    <div className="advanced-search-main-container">
      <div className="advanced-search-container">
        <div className="advanced-search-heading">
          <h2>{ADVANCE_SEARCH_HEADING}</h2>
        </div>

        <div className="advanced-search-input-container">
          <TextField
            className="advance-search-input"
            id="outlined-basic"
            label="Search Entities"
            variant="outlined"
            value={companyToSearch}
            onChange={(e) => setCompanyToSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Search Method
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={companyToSearchMethod}
              onChange={handleChange}
              className="advance-search-method"
              label="Search method"
            >
              <MenuItem value="contains">Contains</MenuItem>
              <MenuItem value="starts_with">Starts with</MenuItem>
              <MenuItem value="ends_with">Ends with</MenuItem>
            </Select>
          </FormControl>

          <Button type="submit" variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </div>

        <div className="advance-search-result">
          <AdvanceSearchResultsComponent searchText={searchText} searchMethod={searchMethod} />
          <div className="advance-search-results-legend">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "#f9ccc9",
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              ></div>
              <span>{ADVANCE_SEARCH_RISK_LEGEND}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearchScreen;
