import { createSlice } from "@reduxjs/toolkit";

import { getCompanyDetailsThunk, getAllCompaniesThunk,getSupplierDataThunk,getCustomerDataThunk } from "../../services/company-details-thunks";

const initialState = {
    company_details: {},
    loading: true
}

const companyDetailsPageSlice = createSlice({
    name: 'company_details',
    initialState,
    extraReducers: {
        [getCompanyDetailsThunk.pending]:
            (state) => {
                state.loading = true
                state.company_details = {}
            },
        [getCompanyDetailsThunk.fulfilled]:
            (state, { payload }) => {
                state.loading = false
                state.company_details = payload
            },
        [getCompanyDetailsThunk.rejected]:
            (state) => {
                state.loading = false
            },
        [getSupplierDataThunk.pending]:
            (state) => {
                state.loading = true
                state.company_details = {}
            },
        [getSupplierDataThunk.fulfilled]:
            (state, { payload },action) => {
                state.loading = false
                state.company_details = payload
                action = 'done'
            },
        [getSupplierDataThunk.rejected]:
            (state) => {
                state.loading = false
            },
        [getCustomerDataThunk.pending]:
            (state) => {
                state.loading = true
                state.company_details = {}
            },
        [getCustomerDataThunk.fulfilled]:
            (state, { payload },action) => {
                state.loading = false
                state.company_details = payload
                action = 'test'
            },
            
        [getCustomerDataThunk.rejected]:
            (state) => {
                state.loading = false
            },
        [getAllCompaniesThunk.pending]:
            (state) => {
                state.loading = true
                state.company_details = []
            },
        [getAllCompaniesThunk.fulfilled]:
            (state, { payload }) => {
                state.loading = false
                state.company_details = payload
            },
        [getAllCompaniesThunk.rejected]:
            (state) => {
                state.loading = false
            }

    }

});

export default companyDetailsPageSlice.reducer;