import React from "react";

const InfoCardComponent = ({ img, heading, pos, text, color }) => {
  const textStyle = {
    color: color,
  };
  if (pos === "top") {
    return (
      <div className="info-card-top-container">
        <div className="info-card-img">
          <img width={200} src={img} alt="our logo" tabIndex={0}></img>
        </div>

        <div className="info-card-content">
          <div className="info-card-heading" style={textStyle}>
            {heading}
          </div>
          <div className="info-card-text">{text}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="info-card-left-container">
        <div className="info-card-img">
          <img width={150} src={img} alt="our logo" tabIndex={0}></img>
        </div>

        <div className="info-card-content">
          <div className="info-card-heading" style={textStyle}>
            {heading}
          </div>
          <div className="info-card-text">{text}</div>
        </div>
      </div>
    );
  }
};

export default InfoCardComponent;
