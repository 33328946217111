import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

function CopyDialog({ open, onClose, errorResponse }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(errorResponse).then(function() {
    //   alert("Error response copied to clipboard: " + errorResponse);
    }, function() {
    //   alert("Failed to copy error response to clipboard.");
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Error Response</DialogTitle>
      <DialogContent>
        <Typography>{errorResponse}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <IconButton aria-label="Copy" onClick={copyToClipboard}>
          <FileCopyIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

export default CopyDialog;
