import React, {useEffect} from "react";
import { useState, useRef } from "react";
import { TAG_LINE } from "../../constants";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button, Divider } from "@mui/material";
import "./login.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";

import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constants";

import { MDBBtn } from "mdb-react-ui-kit";

import * as loginService from "../../services/login-service";
import { getUserThunk } from "../../services/user-thunk";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";

const Signin = ({ setIsAuthenticated, changeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);

  const [loginInput, setLoginInput] = useState({});

  const [loginLoading, setLoginLoading] = useState(false);
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
  const [captchaValue, setCaptcha] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoginLoading(true);
    loginInput['g-recaptcha-response'] = captchaValue
    loginService
      .login(loginInput)
      .then((response) => {
        setLoginError(false);
        const { access, userId } = response.data;
        const redirectPath = localStorage.getItem('redirectPath') || '/';
        localStorage.setItem("accessToken", access);
        dispatch(getUserThunk());
        setIsAuthenticated(true);
        setLoginLoading(false);
        navigate(redirectPath);
        try {
          if (window.umami) {
              window.umami.track('userLogin', {
                  "userId": userId,
                  "loginTimestamp":new Date().toISOString() 
              });
          } else {
              console.log("window.umami not there");
          }
        } catch (error) {
            console.error("An error occurred:", error);
        }

        localStorage.removeItem('redirectPath');
      })
      .catch((error) => {
        if(error.response.data?.detail === "User Not Verified") {
          alert("Please Complete Verfication")
          changeTab("verify")
          return;
      }
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setLoginError(error.response.data || 'Unknown error occurred');
      });
    
  };
  const recaptchaRef = useRef(null);

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setLoginInput({
      ...loginInput,
      [name]: newValue,
    });
  };

  const setRegister = (event) => {
    changeTab("register");
  };

  const setResetPassword = (event) => {
    changeTab("forgot");
  }

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
    setIsCaptchaValidated(true);
  }
  return (
    <div className="d-flex flex-column ms-5">
      <div className="login-header" >
              <img
               className="login-page-logo"
                src="logo/ST_OneLine_RGB.png"
                alt="our logo"
              ></img>
              <h3 className="homepage-logo-tagline">Open supply chain intelligence for a just world</h3>
      </div>
      <div style={{textAlign: 'left', marginBottom: '1.5rem', color: '#0062b2'}}>
            <h4 >Log-in or Register</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                fullWidth
                required
                aria-label="username text box"
                name="username"
                type="text"
                label="Email"
                onChange={handleInput}
              />
            }
          </Grid>
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                required
                fullWidth
                aria-label="password text box"
                name="password"
                label="Password"
                type="password"
                onChange={handleInput}
              />
            }
          </Grid>
          <Grid item xs={6} className="wd-login-item">
          <div style={{textAlign: 'right', marginRight: '5px', color: '#ED1846'}} >
                <span onClick={setResetPassword}>  Forgot Password? </span>   
            </div>
          </Grid>
            
          <Grid item xs={6} className="wd-login-item">
            {
             <ReCAPTCHA ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChangeCaptcha} />
            }
          </Grid>
          {
            loginError && (
              <p style={{color: "red", marginTop: "15px"}}>{loginError.detail}</p>
            )
          }
           
          <Grid item xs={12} className="wd-login-item" style={{margin: '10px 0'}}>
            
                <span  style={{ fontSize: '1.1rem'}}>
                  By signing in, you agree to our{' '}
                  <a href="https://info.supplytrace.org/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use </a>and <a href="https://info.supplytrace.org/privacy-policy/ " target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </span>
             
        </Grid>

       
          <Grid item xs={12} className="login-full-width">
            {/* <Button className='wd-submit-btn' variant="contained">
                                Login
                            </Button> */}

            <Button
              className="wd-submit-btn-login"
              startIcon={<LoginIcon />}
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
              disabled={!isCaptchaValidated}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mt-4">
        <div item xs={12} className="wd-login-item" style={{margin: '10px 0'}}>
              <span className="mb-0 mr-2">Don't have an account?</span>
                <MDBBtn outline color="danger" onClick={setRegister}>
                    Register
                </MDBBtn>
              
        </div>
        
    </div>

    </div>
  );
};

export default Signin;