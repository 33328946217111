import React, { useState, useEffect } from 'react';
import "./index.css";
//import img from "../../../public/Contributors/placeholder.jpg"
import ReactMarkdown from 'react-markdown';

const AboutScreen = () => {
  const [markdownContext, setMarkdownContext] = useState('');
  const [markdownMission, setMarkdownMission] = useState('');
  const [markdownAbout, setMarkdownAbout] = useState('');

  useEffect(() => {
    const fetchMarkdownFromGitHub = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/Supply-Chain-Data-Hub/about-supplytrace/main/context.md');
        const content = await response.text();
        setMarkdownContext(content);
        const responseMission = await fetch('https://raw.githubusercontent.com/Supply-Chain-Data-Hub/about-supplytrace/main/Mission.md');
        const rescontent = await responseMission.text();
        setMarkdownMission(rescontent);
        const responseAbout = await fetch('https://raw.githubusercontent.com/Supply-Chain-Data-Hub/about-supplytrace/main/Data%20Hub.md');
        const aboutcontent = await responseAbout.text();
        setMarkdownAbout(aboutcontent);
      } catch (error) {
        console.error('Error fetching Markdown content:', error);
      }
    };

    fetchMarkdownFromGitHub();
  }, []);
  return (
    <div className="about-page-container">
      <div className="about-page-text-container">
        <div className="about-page-mission-container">
          <div className="about-page-mission-content">
            <h1 className="about-page-mission-heading" tabIndex={0}>Mission</h1>
            <div className="about-page-mission-text" tabIndex={0}>
              <ReactMarkdown>{markdownMission}</ReactMarkdown>
              {/* <p>
              
                The Data Hub is an accessible, open data platform providing the
                necessary information and analysis to support change for those
                striving to improve conditions in supply chains. Its mission is
                to inspire and challenge businesses to eradicate supply chain
                abuses and, through the open democratisation of knowledge, to
                enable companies to meet legal and societal expectations for how
                business should sustainably be done.{" "}
              </p> */}
            </div>
          </div>

          <div className="about-page-mission-image" tabIndex={0}>
            <img
              width={"500px"}
              src="about-page/arrow.png"
              alt="a beautiful arrow denoting the determination of mission"
            ></img>
          </div>
        </div>

        <div className="about-page-context-container">
          <div className="about-page-context-image" >
          <img
              width={"500px"}
              tabIndex={0}
              src="about-page/context.jpg"
              alt="a beautiful AI generated pic to depict out context statement"
            ></img>
          </div>
          <div className="about-page-context-content">
            <div className="about-page-context-heading" tabIndex={0}>Context</div>
            <div className="about-page-context-text" tabIndex={0}>
            <ReactMarkdown>{markdownContext}</ReactMarkdown>

              {/* <p tabIndex={0}>
                With increased interest in corporate ESG and responsible
                sourcing, coupled with pending supply chain, human rights due
                diligence and environmental legislation being enacted around the
                world, access to transparent, open data on forced labor and
                other abuses in global supply chains has never been more
                important - or sought after. A more thorough and complete
                understanding of supply chain conditions is increasingly likely
                to result in a significant shift in corporate purchasing
                practices and supply chain conditions.
              </p>

              <p tabIndex={0}>
                {" "}
                Much of this data about supply chains exists in other places -
                but, more often than not, it is locked behind paywalls and
                grounded not in accountability, but in serving the interests of
                high paying customers. Opening up this type of data levels the
                playing field and provides equitable access to high quality
                information, to all.
              </p> */}
            </div>
          </div>
        </div>

        <div className="about-page-datahub-container">
          <div className="about-page-datahub-content">
            <div className="about-page-datahub-heading" tabIndex={0}>The Data Hub</div>
            <div className="about-page-datahub-text" tabIndex={0}>
              <ReactMarkdown>{markdownAbout}</ReactMarkdown>
              {/* <p tabIndex={0}>
                Launching to the public in Feb / March 2024, Phase 1 of the Data
                Hub will be centered on Xinjiang apparel data, to serve as proof
                of concept and pilot the platform. The Data Hub is being built
                for scale, with the ultimate intention to roll out the approach
                across other sectors, geographies, and categories of risk.
              </p>
              <p tabIndex={0}>
                The tool combines expert desk-based risk investigations with
                machine learning/AI powered mapping of customs records and other
                supply chain relevant data. The result is an interactive
                dashboard that empowers all stakeholders to dive into supply
                chain transparency.
              </p> */}
            </div>
          </div>
          <div className="about-page-datahub-image" tabIndex={0}>
            <img
              width={"500px"}
              tabIndex={0}
              src="about-page/supply-chain.jpeg"
              alt="depiction of supply chain with earth map and ships"
            ></img>
          </div>
        </div>
        <div className="about-page-text-quote">
          {/* <h1>Mission</h1>
          <p>
            The Data Hub is an accessible, open data platform providing the
            necessary information and analysis to support change for those
            striving to improve conditions in supply chains. Its mission is to
            inspire and challenge businesses to eradicate supply chain abuses
            and, through the open democratisation of knowledge, to enable
            companies to meet legal and societal expectations for how business
            should sustainably be done.{" "}
          </p>
          <h1>Context</h1>
          <p>
            With increased interest in corporate ESG and responsible sourcing,
            coupled with pending supply chain, human rights due diligence and
            environmental legislation being enacted around the world, access to
            transparent, open data on forced labor and other abuses in global
            supply chains has never been more important - or sought after. A
            more thorough and complete understanding of supply chain conditions
            is increasingly likely to result in a significant shift in corporate
            purchasing practices and supply chain conditions.
          </p>

          <p>
            {" "}
            Much of this data about supply chains exists in other places - but,
            more often than not, it is locked behind paywalls and grounded not
            in accountability, but in serving the interests of high paying
            customers. Opening up this type of data levels the playing field and
            provides equitable access to high quality information, to all.
          </p>
          <h1>The Data Hub</h1>
          <p>
            Launching to the public in Feb / March 2024, Phase 1 of the Data Hub
            will be centered on Xinjiang apparel data, to serve as proof of
            concept and pilot the platform. The Data Hub is being built for
            scale, with the ultimate intention to roll out the approach across
            other sectors, geographies, and categories of risk.
          </p>
          <p>
            The tool combines expert desk-based risk investigations with machine
            learning/AI powered mapping of customs records and other supply
            chain relevant data. The result is an interactive dashboard that
            empowers all stakeholders to dive into supply chain transparency.
          </p> */}
        </div>
      </div>

      <div className="about-page-team-container">
        <div tabIndex={0} aria-label="our team and contributors sections">
          <h2>Team</h2>
        </div>

        <div className="about-page-contributors-container">
          <div className="about-page-contributor">
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor1 profile"
              tabIndex={0}

            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Shawn Bhimani</h3>
              <h3>Lead</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Samantha Rearick</h3>
              <h3>Lead</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Surya Krishnamurthy</h3>
              <h3>Developer</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Mital Bhanushali</h3>
              <h3>Developer</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Satvik Khetan</h3>
              <h3>Developer</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Jayaraman Venkatesan</h3>
              <h3>Developer</h3>
            </div>
          </div>
          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Shawn Bhimani</h3>
              <h3>Lead</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Shawn Bhimani</h3>
              <h3>Lead</h3>
            </div>
          </div>

          <div className="about-page-contributor" tabIndex={0}>
            <img
              width={200}
              src="Contributors/placeholder.jpg"
              alt="contributor profile"
            ></img>
            <div className="about-page-contributor-info" tabIndex={0}>
              <h3>Shawn Bhimani</h3>
              <h3>Lead</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutScreen;
