import React, { useState, Fragment, useEffect, useCallback } from 'react'
import './index.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Autocomplete from '@mui/material/Autocomplete';
import { Button, TextField } from '@mui/material';

import YearFilter from '../../YearFilter';
import CircularProgress from '@mui/material/CircularProgress';

import { axiosRequest } from '../../../services/utils/axios';

import axios from 'axios';
import { API_BASE } from '../../../constants';

import Chip from '@mui/material/Chip';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: red[600],
        '&:hover': {
            backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: red[600],
    },
  }));
const SupplierMapFilterComponent = ({ numberOfTiers,tierNames, handleFilterSelection, is_customer }) => {


    const [filterStatus, setFilterStatus] = useState("draft") // draft or applied

    const [expandFilter, setExpandFilter] = useState(true)
    
    const [currentSearchableComapnies, setCurrentSearchableComapnies] = useState()

    const [supplierSearchResults, setSupplierSearchResults] = useState([]);

    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        const filteredDictList = tierNames?.filter(({ tier }) => tier <= 3);
        const selectedTierCountries = [...new Set(filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableComapnies(selectedTierCountries)
    }, [tierNames]);

    const [supplierFilters, setSupplierFilters] = useState({
        draft: {
            tier: numberOfTiers, countries: [], suppliers: [], is_sus: false
        },
        applied: {
            tier: numberOfTiers, countries: [], suppliers: [], is_sus: false
        },
    })

    const handleTierChange = (event) => {
        const selectedTier = event.target.value
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], tier: selectedTier } }
        setSupplierFilters(filters)
        const filteredDictList = tierNames?.filter(({ tier }) => tier <= selectedTier);

        const selectedTierCountries = [...new Set(filteredDictList?.flatMap(({ shipperName }) => shipperName))];

        setCurrentSearchableComapnies(selectedTierCountries)
    }

    const handleTierDeletion = (_event) => {
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], tier: "" } }

        setSupplierFilters(filters)
        const filteredDictList = tierNames?.filter(({ tier }) => tier <= 1);

        const selectedTierCountries = [...new Set(filteredDictList?.flatMap(({ shipperName }) => shipperName))];

        setCurrentSearchableComapnies(selectedTierCountries)
    }

    const handleCountryDeletion = (countryToBeDeleted) => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                countries: supplierFilters[filterStatus].countries.filter((country) => country !== countryToBeDeleted)
            }
        }
        setSupplierFilters(filters)
    }


    const handleSuppliersChange = (_event, selectedSuppliers) => {
        console.log("selectedSuppliers", selectedSuppliers)
        const newSelectedCountries = [...new Set([...selectedSuppliers])]
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], suppliers: newSelectedCountries } }
        setSupplierFilters(filters)
    };

    const handleSupplierDeletion = (supplierToBeDeleted) => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                suppliers: supplierFilters[filterStatus].suppliers.filter((supplier) => supplier != supplierToBeDeleted)
            }
        }
        setSupplierFilters(filters)
    }


    const handleSupplierInputChange = (event) => {
        const searchTerm = event.target.value
        setSearchLoading(true)
        console.log(currentSearchableComapnies);
        const filteredCompanies = currentSearchableComapnies.filter(company => searchTerm && company.toLowerCase().startsWith(searchTerm.toLowerCase()));
        setSupplierSearchResults(filteredCompanies);
        setSearchLoading(false)
    }
    const [is_sus, setIsSus] = useState(false);

    const setSus = () => {
      setIsSus((prevSuspiciousOnly) => {
          return !prevSuspiciousOnly;
      });
    }; 
    const handleFilterCancel = () => {
        setExpandFilter(false)
        setFilterStatus("draft")
        const filters = { ...supplierFilters, draft: { ...supplierFilters["applied"] } }
        setSupplierFilters(filters)
        const filteredDictList = tierNames?.filter(({ tier }) => tier <= 1);

        const selectedTierCountries = [...new Set(filteredDictList?.flatMap(({ shipperName }) => shipperName))];

        setCurrentSearchableComapnies(selectedTierCountries)
    }

    const handleApplyFilter = () => {
        // TODO call API to apply filter
        setExpandFilter(false)
        const filters = { ...supplierFilters, applied: { ...supplierFilters["draft"] } }
        setSupplierFilters(filters)
        setFilterStatus("applied")
        handleFilterSelection(supplierFilters["draft"])
    }
    useEffect(()=> {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                is_sus: is_sus
            }
        }
        setSupplierFilters(filters)
    },[is_sus]);
    return (
        <div className={`${expandFilter ? 'supplier-map-filter-main-expanded-container' : 'supplier-map-filter-main-container'}`}>
            <Button
                variant="outlined"
                startIcon={<TuneIcon />}
                endIcon={expandFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                color="info"
                className='supplier-filters-btn'
                aria-label='Filter options open'
                disabled={expandFilter}
                onClick={() => { setFilterStatus('draft'); setExpandFilter(true)}}
            >
                Map Filters
            </Button>
            <div className={`${expandFilter ? 'supplier-filter-secondary-expanded-container' : 'supplier-filter-secondary-container'}`}>
                {
                    expandFilter && <div className='supplier-filter-container'>
                        <div className='supplier-filter-header'>
                        </div>
                        <div className='filter-map-container-suspicious-filter'>
                                <div className='supplier-filter-name-text'>
                                    Potential Supply Chain Risk Only
                                </div>
                                <RedSwitch
                                    checked={is_sus}
                                    onChange={setSus}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        <div className='supplier-tiers-filter'>
                            
                            <FormControl fullWidth>
                                <InputLabel id="supplier-filter-select-label">Tiers</InputLabel>
                                <Select
                                    labelId="supplier-filter-select-label"
                                    id="supplier-filter-select"
                                    value={supplierFilters[filterStatus].tier}
                                    label="Tiers"
                                    onChange={handleTierChange}>
                                    {Array.from({ length: numberOfTiers }, (_, i) => i + 1).map((item) => <MenuItem key={item} value={item}>{`Up to ${item}`}</MenuItem>)}
                                </Select>
                            </FormControl>

                        </div>

                        <div className='supplier-suppliers-filter'>
                            <Autocomplete
                                multiple
                                disablePortal
                                id="supplier-suppliers-filter"
                                options={supplierSearchResults}
                                sx={{ width: '100%' }}
                                value={supplierFilters[filterStatus].suppliers}
                                loading={searchLoading}
                                onInputChange={handleSupplierInputChange}
                                onChange={handleSuppliersChange}
                                renderTags={() => null}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Search Companies"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            borderRadius: "0px",
                                        },
                                        endAdornment: (
                                            <Fragment>
                                                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                        )
                                    }}
                                />
                                }
                            />
                        </div>
                    </div>
                }
                <div className='selected-suppliers-container'>
                    {
                        supplierFilters[filterStatus]?.tier && <div className='selected-supplier-container'>
                            <Chip
                                color="primary"
                                label={`Tiers | Up to ${supplierFilters[filterStatus]?.tier}`}
                                variant='filled'
                                {...expandFilter ? { onDelete: handleTierDeletion, variant: "filled" } : {variant: "filled"}}
                            />
                        </div>
                    }
                    {
                        supplierFilters[filterStatus]?.is_sus && <div className='selected-supplier-container'>
                            <Chip
                                color="error"
                                label={`Potential Supply Chain Risk Only`}
                                variant='filled'
                                {...expandFilter ? { onDelete: setSus, variant: "filled" } : {variant: "filled"}}
                            />
                        </div>
                    }
                    {
                        supplierFilters[filterStatus]?.countries?.map(
                            (country) => <div key={country} className='selected-supplier-container'>
                                <Chip key={country}
                                    color="secondary"
                                    label={`Country | ${country}`}
                                    {...expandFilter ? { onDelete: () => handleCountryDeletion(country), variant: "outlined" } : {variant: "outlined"}}
                                />
                            </div>
                        )
                    }
                    {
                        supplierFilters[filterStatus]?.suppliers?.map(
                            (supplier) => <div key={supplier} className='selected-supplier-container'>
                                <Chip key={supplier}
                                    color="success"
                                    label={`${is_customer ? "Customer" : "Supplier"} | ${supplier}`}
                                    {...expandFilter ? { onDelete: () => handleSupplierDeletion(supplier), variant: "outlined" } : {variant: "outlined"}}
                                    variant="outlined"
                                />
                            </div>
                        )
                    }
                    
                </div>

                {expandFilter && <div className='supplier-apply-filters-btn-container'>
                    <Button
                        className='clear-supplier-filters-btn'
                        aria-label="cancel button"
                        variant="outlined"
                        onClick={handleFilterCancel}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='supplier-apply-filters-btn'
                        aria-label="apply filter"
                        variant="contained"
                        onClick={handleApplyFilter}
                    >
                        Apply Filters
                    </Button>
                </div>}
            </div>
        </div>
    )
}

export default SupplierMapFilterComponent;