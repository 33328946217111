import { createSlice } from "@reduxjs/toolkit";

import { getAllCompanyInfoThunk } from "../../services/about-page-thunks"; 



const initialState = {
    company_info: {},
    loading: true
}



const aboutPageDetailsSlice = createSlice({
    name: 'company_info',
    initialState,
    extraReducers: {
        [getAllCompanyInfoThunk.pending]:
            (state) => {
                state.loading = true
                state.company_info = {}
            },
        [getAllCompanyInfoThunk.fulfilled]:
            (state, { payload }) => {
                state.loading = false
                state.company_info = payload
            },
        [getAllCompanyInfoThunk.rejected]:
            (state) => {
                state.loading = false
            }
        }
});

export default aboutPageDetailsSlice.reducer;



