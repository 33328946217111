// DataGridComponent.js
import React from "react";
import { DataGrid, GridToolbar,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarDensitySelector,GridToolbarFilterButton } from '@mui/x-data-grid';
import './index.css'
const LocationsGridComponent = ({ locations }) => {
  // Assuming locations is an array of objects with appropriate fields
  const columns = [
    {
        field: 'company_name_english',flex:1,
        headerName: 'Location Name',
        // maxWidth: 200,
        cellClassName: 'custom-cell',

        renderHeader: () => (
            <div style={{fontWeight: 'bold', fontSize: '18px'}}>
                {'Location Name'}
            </div>
        ),
    },
    {
        field: 'full_address', headerName: 'Address',
        flex:1,
        cellClassName: 'custom-cell',

        headerClassName: "tier-supplier-table-header-modal",       
        renderHeader: () => (
            <div style={{fontWeight: 'bold', fontSize: '18px'}}>
                {'Address'}
            </div>
        ),
    },
  ];
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{ border: '1px solid #eee', width: "100%" }}>
     <DataGrid
                  getRowHeight={() => 'auto'} 
                  rows={locations.map((item, index) => ({
                    id: index + 1, // Add a numerical ID
                    ...item, 
                  }))}
                  sx={{
                    boxShadow: 4,
                    border: 2,
                    borderColor: '#006EBE',
                    '& .MuiDataGrid-cell:hover': {
                    color: '#006EBE',
                    },
                }}                  columns={columns}
                  components={{ Toolbar: CustomToolbar }}
                  autoHeight="true"
                  density="compact"
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  getRowClassName={() => 'custom-cell'}
                  pageSizeOptions={[10, 15]}
                />
    </div>
  );
};

export default LocationsGridComponent;
