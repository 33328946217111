/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, PureComponent } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { DataGrid, GridToolbar,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarDensitySelector,GridToolbarFilterButton } from '@mui/x-data-grid';
import { Typography } from '@material-ui/core';
import CustomPieChart from "../PieChartComponent";
import Skeleton from "@mui/material/Skeleton";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { getAllCompanyInfoThunk } from "../../../services/about-page-thunks";
import { SHU_INFO } from "../../../constants";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from "recharts";
import { PieChart, Pie, Cell } from 'recharts';

import "./index.css";
import DashboardSummaryComponent from "../Summary/DashboardSummaryComponent";
import { max, min } from "lodash";
import CompanyLocations from "../CompanyLocations/CompanyLocations";
import { axiosRequest } from "../../../services/utils/axios";
import { API_BASE } from "../../../constants";
import RedactLogin from "../../RedactLogin";
import RedactLoginComponent from "../../RedactLogin/RedactLoginComponent";
const chartSetting = {
  xAxis: [
    {
      label: "total transactions",
    },
  ],
  width: 500,
  height: 500,
};

const AboutComponent = ({ company, companyName , setCompanyAddress }) => {
    const columns = [
        {
            field: 'transaction_date',flex:1,
            headerName: 'Transaction date',
            // maxWidth: 200,
          
            renderHeader: () => (
                <div className='tier-supplier-table-header-modal'>
                    {'Transaction date '}
                </div>
            ),
        },
        {
            field: 'media_reported_date', headerName: 'Observed date',
            flex:1,
            headerClassName: "tier-supplier-table-header-modal",
            // maxWidth: 200,
           
            renderHeader: () => (
                <div className='tier-supplier-table-header-modal'>
                    {'Observed date '}
                </div>
            ),
        },
        {
            field: 'source', headerName: 'Source', 
            flex:4,
            headerClassName: "tier-supplier-table-header-modal",
            // maxWidth: 800,
            
            renderHeader: () => (
                <div className='tier-supplier-table-header-modal'>
                    {'Source '}
                </div>
            ),
            renderCell : (params) => {
                return <div onClick={sourceClicked} dangerouslySetInnerHTML={{__html:`${params.value}`}}></div>
            },
        }

    ];
    const { currentUser } = useSelector((state) => state.user);

    const sourceClicked = () => {
      try {
        let organisationType = currentUser?.organisation_type ?? "unknown";
        organisationType = organisationType.split(' ')[0];
        if (window.umami) {
            window.umami.track('EvidenceClicked '+ organisationType, {
                "company": companyName + " ("+company+")" ,
            });
        } else {
            console.log("window.umami not there");
        }
      } catch (error) {
          console.error("An error occurred:", error);
      }
    }
    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      );
    }
    
  const [company_info, setCompanyInfo ] = useState({})
  const [loading, setLoading] = useState(false);

  const [showLoginComponent, setShowLoginComponent] = useState(false);
  

  useEffect(() => {
      fetchLocations()
      fetch_company_info()
  }, []);

  useEffect(() => {
    if(currentUser && ('username' in currentUser)) {
      setShowLoginComponent(false)
    } else {
      setShowLoginComponent(true)
    }
  }, [currentUser]);
  const [shipmentCount, setShipmentCount] = useState(0);
  const [minYear, setMinYear] = useState(2014);
  const [maxYear, setMaxYear] = useState(2024);
  const [source_investigation, setSourceInvestigation] = useState(false);

  useEffect(() => {
    var shipments = 0
    var minyear = 2024
    var maxyear = 2014
    console.log(company_info)
    if (company_info?.data) {
      const yearData = company_info?.data?.year_data
      yearData.forEach((data) => {
        shipments+=(data.received_total_transactions + data.supplied_total_transactions);
        minyear = min(minyear, data.year)
        maxyear = max(maxyear, data.year)
      })
    }
    setMinYear(minYear);
    setMaxYear(maxYear);
    setShipmentCount(shipments);
    if(company_info?.data?.address){
      setCompanyAddress(company_info?.data?.address);
    }
    setSourceInvestigation(company_info?.data?.source_investigation);
  }, [company_info]);
  const [companyLocations, setCompanyLocations] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);

  const fetchLocations = async () => {
        try {
            const response = await axiosRequest.get(`${API_BASE}/loadCompanyLocations/?company=${company}`);
            setCompanyLocations(response.data?.locations);
            setCompanyDetails(response?.data?.company)
        }
        catch (error) {
            console.log(error)
        }
    }
    const fetch_company_info = async () => {
      try {
        const response = await axiosRequest.get(`${API_BASE}/getAllCompanyInfo/?search=${company}`);
        setCompanyInfo(response)
      } catch (error) {
        console.log(error)
        if(error?.response?.status === 401) {
            setShowLoginComponent(true);
        }
      }
      setLoading(false)
    }
  return (
    <>
      {loading && (
        <>
          <Grid container wrap="nowrap">
            {Array.from(new Array(2)).map((item, index) => (
              <Box
                key={index}
                sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}
              >
                <Skeleton variant="rectangular" width={"90%"} height={320} />
              </Box>
            ))}
          </Grid>
          <Grid container wrap="nowrap">
            {Array.from(new Array(2)).map((item, index) => (
              <Box
                key={index}
                sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}
              >
                <Skeleton variant="rectangular" width={"90%"} height={320} />
              </Box>
            ))}
          </Grid>
        </>
      )}

      {!loading && (
        <div className="about-component-container">
          <h1 className="about-component-header">Dashboard</h1>
          
          {!showLoginComponent && (<div style={{  marginTop: "1rem"}}>
              <DashboardSummaryComponent entityName={companyName} shipmentCount={shipmentCount.toLocaleString()} locCount={companyLocations.length.toLocaleString()} firstYear={minYear} endYear={maxYear} />
          </div>)}
          {source_investigation && (<div className="risk-indicator-container ">
            <p>The potential risk indicator for this entity has been added by the Sheffield Hallam University Forced Labor Lab</p>
          </div>)}
          <CompanyLocations company={company} companyLocations={companyLocations} companyDetails={companyDetails}/>
          {
                    showLoginComponent && (
                      <RedactLoginComponent companyName={companyName} />
                    )
          }
          {!showLoginComponent && 
            <div>
                {company_info?.data?.evidences?.length > 0 && (
                  <div>
                    <h1 className="about-component-header">Evidence Of Risk</h1>
                  </div>
                )}
                
                <div className="about-charts-container">
                  {company_info?.data?.evidences?.length > 0 && (
                    <div className="about-chart-container about-evidence">
                    {company_info?.data?.evidences?.length > 0 && (
                    
                    <div className="shu_info">
                      <p>{SHU_INFO}</p>
                    </div>
                    
                  )}
                      <DataGrid
                        // owHeight={95}
                        getRowHeight={() => 'auto'} 
                        rows={company_info?.data?.evidences.map((item, index) => ({
                          id: index + 1, // Add a numerical ID
                          ...item, // Spread the existing properties
                        }))}
                        
                        columns={columns}
                        components={{ Toolbar: CustomToolbar }}
                        autoHeight="true"
                        density="compact"
                        initialState={{
                          pagination: { paginationModel: { pageSize: 5 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                      />
                      
                    </div>
                  )}
                  
                  
                {company_info?.data?.year_data && ( <div className="about-chart-container about-chart-line-chart">
                    <div className="about-chart-container-header">
                      Year Breakdown Chart
                    </div>
                    <LineChart
                      width={930}
                      height={350}
                      data={company_info?.data?.year_data}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="linear"
                        dataKey="received_total_transactions"
                        stroke="#8884d8"
                      />
                      <Line
                        type="linear"
                        dataKey="supplied_total_transactions"
                        stroke="#82ca9d"
                      />
                    </LineChart>
                  </div>)}

                  {company_info?.data?.top_suppliers?.length > 0 && (
                    <div className="about-chart-container">
                      <div className="about-chart-container-header">
                        Top Suppliers
                      </div>
                      <BarChart
                        width={450}
                        height={440}
                        data={company_info?.data?.top_suppliers}
                        barGap={2}
                        barSize={55}
                      >
                        <XAxis
                          dataKey="supplier"
                          tick={{ fontSize: 8, width: 25 }}
                          name="Height"
                          angle={20}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} />
                        <Bar dataKey="total_transactions" fill="#79CCB3" />
                      </BarChart>
                    </div>
                  )}

                  {company_info?.data?.top_customers?.length > 0 && (
                    <div className="about-chart-container">
                      <div className="about-chart-container-header">
                        Top Customers
                      </div>
                      <BarChart
                        width={450}
                        height={440}
                        data={company_info?.data?.top_customers}
                        barGap={2}
                        barSize={55}
                      >
                        <XAxis
                          dataKey="customer"
                          tick={{ fontSize: 8, width: 25 }}
                          name="Height"
                          angle={20}
                        />
                        <YAxis />
                        <Tooltip />

                        <Bar dataKey="total_transactions" fill="#00094b" />
                      </BarChart>
                    </div>
                  )}

                  {company_info?.data?.top_supplying_countries?.length > 0 && (
                    <div className="about-chart-container">
                      <div className="about-chart-container-header">
                        Top Supplying Countries
                      </div>
                      <BarChart
                        width={450}
                        height={440}
                        data={company_info?.data?.top_supplying_countries}
                        barGap={2}
                        barSize={55}
                      >
                        <XAxis
                          dataKey="country_name"
                          tick={{ fontSize: 10, width: 5 }}
                        />
                        <YAxis />
                        <Tooltip />

                        <Bar dataKey="count" fill="#00094b" />
                      </BarChart>
                    </div>
                  )}

                  {company_info?.data?.top_buying_countries?.length > 0 && (
                    <div className="about-chart-container">
                      <div className="about-chart-container-header">
                        Top Acquiring Countries
                      </div>
                      <BarChart
                        width={450}
                        height={440}
                        data={company_info?.data?.top_buying_countries}
                        barGap={2}
                        barSize={55}
                      >
                        <XAxis
                          dataKey="country_name"
                          tick={{ fontSize: 10, width: 5 }}
                        />
                        <YAxis />
                        <Tooltip />

                        <Bar dataKey="count" fill="#00094b" />
                      </BarChart>
                    </div>
                  )}

                  {company_info?.data?.top_acquired_products?.length > 0 && (
                    <div className="about-chart-container">
                      <div className="about-chart-container-header">
                        Acquired Products
                      </div>
                      <div>
                        {CustomPieChart(company_info?.data?.top_acquired_products)}
                      </div>
                      
                    </div>
                  )}

                  {company_info?.data?.top_supplied_products?.length > 0 && (
                    <div className="about-chart-container">
                      <div className="about-chart-container-header">
                        Supplied Products
                      </div>
                      <div>
                        {CustomPieChart(company_info?.data?.top_supplied_products)}
                      </div>
                    </div>
                  )}
                </div>
            </div>
          }
           
        </div>
      )}
    </>
  );
};

export default AboutComponent;