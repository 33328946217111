import './index.css';
import React, { useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import GroupIcon from '@mui/icons-material/Group';
import { useLocation } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
const CompanyLeftPanel = ({ companyName, companyAddress}) => {
    const { hash } = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (
        <div className={`company-left-panel-container ${isCollapsed ? "small" : ""}`}>

            <div className='company-name-container' onClick={toggleCollapse}>
                <div>
                    <BusinessIcon fontSize='large' />
                </div>
                <div className='company-name-text'>
                    {companyName}
                </div>
            </div>
            {
                companyAddress?.length > 0 && (
                    <div className='company-address-container'>
                        <div>
                            <LocationOnIcon fontSize='large'/>
                        </div>
                        <div className='company-address-text'>
                            {companyAddress}
                        </div>
                    </div>
                )
            }
            
            <hr className='company-name-divider' style={{ display: isCollapsed ? 'none' : ''}}/>

            <div className='company-division-tabs-container' style={{ display: isCollapsed ? 'none' : ''}}>
                <a
                    className={(!hash || hash === '#dashboard') ? 'company-division-tab-selected-container' : 'company-division-tab-container'}
                    href='#dashboard'
                >
                    <DashboardIcon />
                    <div className='company-division-tab-text' tabIndex={0} aria-label='Navigate to Dashboard'>
                        Dashboard
                    </div>
                </a>

                <a
                    className={hash === '#supplier' ? 'company-division-tab-selected-container' : 'company-division-tab-container'}
                    href='#supplier'
                    tabIndex={0}
                >
                    <Inventory2Icon />
                    <div className='company-division-tab-text' tabIndex={0} aria-label='Navigate to supplier page button'>
                        Supplier
                    </div>
                </a>

                <a
                    className={hash === '#customer' ? 'company-division-tab-selected-container' : 'company-division-tab-container'}
                    href='#customer'
                    tabIndex={0}
                >
                    <GroupIcon />
                    <div className='company-division-tab-text' tabIndex={0} aria-label='Navigate to customer page button'>
                        Customer
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CompanyLeftPanel;