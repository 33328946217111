import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import "./index.css";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import { FEEDBACK_OPTIONS, FEEDBACK_PLACEHOLDER } from "../../constants";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

import { submit_request } from "../../services/help-service";
import { getUserThunk } from "../../services/user-thunk";



const ContactScreen = () => {
    const dispatch = useDispatch()
  const [selectedChip, setSelectedChip] = useState(null);
  const [feedbackText, setFeedbackText] = useState("");
  const [validationError, setValidationError] = useState("");

  const [validationSuccess, setValidationSuccess] = useState("");



  const { currentUser, loading: userLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    console.log("user fetched again")
    dispatch(getUserThunk())
}, [])

  const handleChipClick = (chipLabel) => {
    setSelectedChip(chipLabel);
  };

  const handleSubmit = async () => {
    if (selectedChip && feedbackText.trim() !== "") {
      // Perform the submit action here
      setValidationError(""); // Reset validation error

      const details = {
        username: currentUser.username,
        category: selectedChip,
        text: feedbackText,
      };

      try {
        const response = await submit_request(details);
        setValidationError("")
        setValidationSuccess("Your request is submitted. We will get back to you soon!")
        setFeedbackText("")

        console.log("Response from API:", response);
        // Handle the response as needed
      } catch (error) {
        if (error.response) {
            // The request was made, and the server responded with a status code
            // that falls out of the range of 2xx
            setValidationSuccess("")
            setValidationError("Error while processing. Please try after sometime");
        
      }
      // Handle the response as needed

      console.log("Submitting feedback:", selectedChip, feedbackText);
    }
 } else {
      // Handle validation error
      setValidationSuccess("")
      setValidationError("Category and text are required");
      console.error("Category and text are required");
    }
  };

  return (
    <div className="feedback-page-container">
      <div className="feedback-page-heading">
        <h1>What do you need help with?</h1>
      </div>

      <div className="feedback-page-category">
        <Stack direction="row" spacing={2}>
          {FEEDBACK_OPTIONS.map((label) => (
            <Chip
              key={label}
              label={label}
              variant={selectedChip === label ? "filled" : "outlined"}
              color="primary"
              onClick={() => handleChipClick(label)}
            />
          ))}
        </Stack>
      </div>

      <div className="feedback-page-text-container">
        <div className="feedback-page-text-box">
          <TextField
            placeholder={FEEDBACK_PLACEHOLDER}
            multiline
            fullWidth
            aria-label="text box to enter your concern"
            rows={10}
            maxRows={10}
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
        </div>
      </div>

      {validationSuccess && (
        <div className="feedback-page-validation-success-container">
          <div className="feedback-page-validation-success-text">
            {validationSuccess}
          </div>
        </div>
      )}

      {validationError && (
        <div className="feedback-page-validation-error-container">
          <div className="feedback-page-validation-error-text">
            {validationError}
          </div>
        </div>
      )}

      <div className="feedback-page-submit-container">
        <Button
          variant="contained"
          color="primary"
          aria-label="submit button"
          className="feedback-page-submit-button"
          onClick={handleSubmit}
        >
          Submit Request
        </Button>
      </div>
    </div>
  );
};

export default ContactScreen;
