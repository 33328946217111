import { axiosRequest } from "./utils/axios";

import { API_BASE } from "../constants";

import axios from "axios";

export const login = async (user) => {
  const response = await axiosRequest.post(`${API_BASE}/signin/`, user);
  return response;
};

export const getUser = async () => {
  const response = await axiosRequest.get(`${API_BASE}/user/`);
  return response.data;
};

export const validateToken = async (token, userId) => {
  try {
    const response = await axios.get(
      `${API_BASE}/validatetoken?token=${token}&id=${userId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (resetData) => {
  try {
    const response = await axios.post(`${API_BASE}/change-password`, resetData);
    // If the API response includes specific data on success, you can return it here
    return response.data;
  } catch (error) {
    // Handle errors, for example by rethrowing them to be caught by the caller
    throw error;
  }
};
