import React, { useState, useEffect,useRef } from 'react';
import './index.css';
import Chip from '@mui/material/Chip';
import { Button } from '@mui/material';
import CustomerModalComponent from '../CustomerModalComponent';
import axios from 'axios';
import { axiosRequest } from '../../../services/utils/axios';
import { API_BASE } from '../../../constants';
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SupplierFilterComponent from '../SupplierFilterComponent';

import SupplierMapEntryComponent from '../SupplierMapComponent/MapEntryComponent';
import SankeyEntryComponent from '../SankeyPlot/SankeyEntryComponent';

import CustomerSummaryComponent from '../Summary/CustomerSummaryComponentComponent';
import RedactLogin from '../../RedactLogin';
import RedactLoginComponent from '../../RedactLogin/RedactLoginComponent';


const CustomerComponent = ({ company, companyNameInfo, setCompanyAddress }) => {
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    const [selectedTier, setSelectedTier] = useState();
    const openCustomerModal = (tier) => {
        setIsCustomerModalOpen(true)
        setSelectedTier(tier)
    };
    const closeCustomerModal = () => setIsCustomerModalOpen(false);

    const { currentUser } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const [tiersName, setTiersName] = useState()
    const [customerData, setCustomerData] = useState()
    const [customersLoading, setCustomersLoading] = useState(true)
    const [showLoginComponent, setShowLoginComponent] = useState(false);

    const [currentMinYear, setCurrentMinYear] = useState(2014);
    const [currentMaxYear, setCurrentMaxYear] = useState(2014);
    const [supplierFilters, setSupplierFilters] = useState({
        draft: {
            tier: "3", year: { from: currentMinYear, to: currentMaxYear }
        },
        applied: {
            tier: "3", year: { from: currentMinYear, to: currentMaxYear }
        },
    })
    
    useEffect(() => {
        //dispatch(getCustomerDataThunk({ company: company, product: "ALL", startY: "2015", endY: '2015', tiers: "5", suppliers:[]  }))
        window.scrollTo(0, 0);
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 2;
        
        setCurrentMaxYear(previousYear);
        setCurrentMinYear(previousYear);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if(currentUser && ('username' in currentUser)) {
            fetchCustomers({ company: company, product: "ALL", startY: null, endY: null, tiers: 5, suppliers: [] })
        }
    }, [])
    useEffect((currentUser)=>{
        if(currentUser && ('username' in currentUser)) {
            setShowLoginComponent(false)
            fetchCustomers({ company: company, product: "ALL", startY: null, endY: null, tiers: 5, suppliers: [] })
        } else {
            setShowLoginComponent(true)
        }
    },[currentUser])
    const [companyName, setCompanyName] = useState(companyNameInfo)
    useEffect(()=> {
        document.title = companyName;
    },[companyName])

    const fetchCustomers = async (filterQuery) => {
        try {
            setCustomersLoading(true)
         
            const response = await axiosRequest.get(`${API_BASE}/loadCustomerInfo/`, {params: filterQuery});             
            setShowLoginComponent(false);

            setCompanyAddress(response?.data?.company?.full_address)       
            setCompanyName(response?.data?.company?.company_name_english)
            setCurrentMinYear(response?.data?.startY);
            setCurrentMaxYear(response?.data?.endY);
            const filterStatus = "applied"
            const filters = {
                ...supplierFilters,
                [filterStatus]: {
                    ...supplierFilters[filterStatus],
                    year: {
                        from: response?.data?.startY,
                        to: response?.data?.endY
                    }
                }
            }
            setSupplierFilters(filters)   
            const finalDictList = response?.data?.tiers?.flatMap(({ tier, consignee }) => ({ tier, shipperName: consignee.map(({ company_name_english }) => company_name_english) }));
            setCustomerData(response)
            setTiersName(finalDictList)
            
            setCustomersLoading(false);
            try {
                console.log("filterQuery",filterQuery)
                if (window.umami) {
                    let organisationType = currentUser?.organisation_type ?? "unknown";
                    organisationType = organisationType?.split(' ')[0];
                    window.umami.track('CustomerLoad '+organisationType, {
                        "searchParams": 
                        response?.data?.company?.company_name_english
                        +"/"+company+ "/"+filterQuery?.startY+"/"+filterQuery?.endY,
                    });
                } else {
                    console.log("window.umami not there");
                }
              } catch (error) {
                  console.error("An error occurred:", error);
              }
            return response;
        }
        catch (error) {
            console.log(error)
            setCustomersLoading(false)
            if(error?.response?.status === 401) {
                setShowLoginComponent(true);
                // setCompanyAddress(error?.response?.data?.company?.full_address);
                // setCompanyName(error?.response?.data?.company?.company_name)
            }
        }
    }

    const handleFilterSelection = async (appliedFilter) => {
        const filterQuery = {
            company: company,
            product: "ALL",
            startY: appliedFilter.year.from,
            endY: appliedFilter.year.to,
            suppliers: [],
            tiers: "5"
        }
        setCurrentMinYear(appliedFilter.year.from);
        setCurrentMaxYear(appliedFilter.year.to);
        try {
            if (window.umami) {
                window.umami.track('companySupplierInfoLoad', {"companyId": company, "startYear": appliedFilter.year.from, "endYear": appliedFilter.year.to});
            } else {
                console.log("window.umami not there");
            }
          } catch (error) {
              console.error("An error occurred:", error);
          }
        await fetchCustomers(filterQuery)

    }



    const [suspiciousOnly, setSuspiciousOnly] = useState(false)

    const setSus = () => {
        setSuspiciousOnly((prevSuspiciousOnly) => {
          // You can perform additional actions here if needed
      
          // Update the state and return the new value
          return !prevSuspiciousOnly;
        });

    };

    return (

        <>
        {!showLoginComponent && customersLoading && <><Grid container wrap="nowrap">
                {Array.from(new Array(2)).map((item, index) => (
                    <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                        <Skeleton variant="rectangular" width={"90%"} height={320} />
                    </Box>
                ))}
            </Grid>
                <Grid container wrap="nowrap">
                    {Array.from(new Array(2)).map((item, index) => (
                        <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                            <Skeleton variant="rectangular" width={"90%"} height={320} />
                        </Box>
                    ))}
                </Grid></>}

                <div className='supplier-main-container'>
                <div className='supplier-component-filter-container'>
                    <SupplierFilterComponent
                        supplierFilters = {supplierFilters} 
                        setSupplierFilters = {setSupplierFilters}
                        numberOfTiers={customerData?.data.tiers.length}
                        countries={[...new Set(customerData?.data.tiers.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.supplier_countries.split(",")], []))]}
                        tierNames={tiersName}
                        handleFilterSelection={handleFilterSelection}
                    ></SupplierFilterComponent>
                </div>
                    {!customersLoading && !showLoginComponent && !customerData?.data?.has_customers && (              
                            <div className='no-suppliers'>
                                No Customers Found based on filter parameters
                            </div>
                        )
                    }
                {
                    showLoginComponent && (
                        <RedactLoginComponent companyName={companyName} />
                    )
                 }
                 {!customersLoading && !showLoginComponent && customerData?.data?.has_customers && (

                    <div className='supplier-data-container' >
                        <div>
                            <CustomerSummaryComponent entityName={customerData?.data?.company.company_name_english} shipmentCount={customerData?.data?.tiers[0]?.consignee?.length} firstYear={currentMinYear} endYear={currentMaxYear} risk={customerData?.data?.sus_relations[0]?.length>0} />
                        </div>
                        <SupplierMapEntryComponent allSuppliersData={customerData} tiersName = {tiersName} company_name={companyName} handleFilterSelection={handleFilterSelection} is_customer={true} />
                        <SankeyEntryComponent allSuppliersData={customerData} tiersName = {tiersName} company_name={companyName} handleFilterSelection={handleFilterSelection} is_customer={true} start_year={currentMinYear} end_year={currentMaxYear} />

                        <div className='tier-container'>
                            {
                                customerData.data?.tiers.map((tier, index) => {
                                    return (

                                        <div className='tier-card' key={index}>
                                            <div className={`tier-number ${tier.suspicious_consignee ? 'tier-number-red' : ''}`}> {`Tier ${tier.tier}`}
                                            {tier.suspicious_consignee &&(
                                                <Tooltip
                                                title="Connections to High-Risk Entities are found in this tier"
                                                placement="right-end"
                                                arrow
                                                style={{ marginLeft: '10px' }}
                                                >
                                                <InfoIcon></InfoIcon>
                                                </Tooltip>                                                 
                                            ) }
                                            </div>
                                            {tier.receiver_countries.length>0 && (
                                                <div className='tier-countries-container'>
                                                    <div className='tier-countries-header'>
                                                        Countries
                                                    </div>
                                                    <div className='tier-countries'>
                                                        <>
                                                            {
                                                                tier.receiver_countries.split(",").slice(0, 20).map((country, index) => {
                                                                    return (
                                                                        <Chip className='tier-country' label={country} color="primary" variant="filled" key={country}></Chip>
                                                                    )
                                                                }
                                                                )}
                                                        </>{
                                                            ((tier.supplier_countries.split(",").length - 20) > 0) && <Chip className='tier-country' label={(`+${tier.supplier_countries.split(",").length - 20}`)} color="primary" >

                                                            </Chip>}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='tier-suppliers-container' style={{ paddingTop: tier.receiver_countries.length === 0 ? '30px' : '10px' }}>
                                                <div className='tier-suppliers-header'>
                                                    Customers
                                                </div>

                                                <div className='tier-suppliers'>
                                                    <>
                                                        {tier.consignee.slice(0, 2).map(({ company_name_english, full_address }) => {
                                                            return (
                                                                <div className='tier-supplier-container' key={company_name_english}>
                                                                    <div>
                                                                        {company_name_english}
                                                                    </div>
                                                                    <div className='tier-supplier-address'>
                                                                        {full_address}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                        {tier.consignee.length > 2 && <div className='tier-supplier-more-text'>{`...and ${(tier.consignee.length - 2)} more`}</div>}
                                                    </>
                                                </div>

                                            </div>
                                            <Button className='learn-more-btn' aria-label={`Click this button to learn more about the ${tier.tier} tier`} onClick={() => openCustomerModal(tier)}>
                                                Learn More
                                            </Button>
                                        </div>
                                    )
                                })}
                          
                            {isCustomerModalOpen && <CustomerModalComponent open={isCustomerModalOpen} handleClose={closeCustomerModal} tier={selectedTier} />}
                        </div>
                        


                    </div>)}
                </div >
            

        </>




        // <>

        //     {loading && <><Grid container wrap="nowrap">
        //         {Array.from(new Array(2)).map((item, index) => (
        //             <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
        //                 <Skeleton variant="rectangular" width={"90%"} height={320} />
        //             </Box>
        //         ))}
        //     </Grid>
        //         <Grid container wrap="nowrap">
        //             {Array.from(new Array(2)).map((item, index) => (
        //                 <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
        //                     <Skeleton variant="rectangular" width={"90%"} height={320} />
        //                 </Box>
        //             ))}
        //         </Grid></>
        //     }
        //     {

        //         <div className='year-drop-down-container'>
        //             <YearFilter handleYearsSelection={handleYearsSelection}></YearFilter>
        //         </div>
        //     }
        //     {
        //         !loading && !company_details.data?.has_customers && (
        //             <div className='no-suppliers'>
        //                 No Customers Found
        //             </div>
        //         )
        //     }
        //     {
        //         !loading && company_details.data?.has_customers && (
        //             <div className='tier-container'>
        //                 {
        //                     company_details.data?.tiers.map((tier, index) => {
        //                         return (

        //                             <div className='tier-card' key={index}>
        //                                 <div className='tier-number'> {`Tier ${tier.tier}`}</div>
        //                                 <div className='tier-countries-container'>
        //                                     <div className='tier-countries-header'>
        //                                         Countries
        //                                     </div>
        //                                     <div className='tier-countries'>
        //                                         <>
        //                                             {
        //                                                 tier.receiver_countries.split(",").slice(0, 20).map((country, index) => {
        //                                                     return (
        //                                                         <Chip className='tier-country' label={country} color="primary" variant="outlined" key={country}></Chip>
        //                                                     )
        //                                                 }
        //                                                 )}
        //                                         </>{
        //                                             ((tier.receiver_countries.split(",").length - 20) > 0) && <Chip className='tier-country' label={(`+${tier.receiver_countries.split(",").length - 20}`)} color="primary" >

        //                                             </Chip>}
        //                                     </div>
        //                                 </div>
        //                                 <div className='tier-suppliers-container'>
        //                                     <div className='tier-suppliers-header'>
        //                                         Customers
        //                                     </div>

        //                                     <div className='tier-suppliers'>
        //                                         <>
        //                                             {tier.consignee.slice(0, 2).map(({ company_name_english, full_address }) => {
        //                                                 return (
        //                                                     <div className='tier-supplier-container' key={company_name_english}>
        //                                                         <div>
        //                                                             {company_name_english}
        //                                                         </div>
        //                                                         <div className='tier-supplier-address'>
        //                                                             {full_address}
        //                                                         </div>
        //                                                     </div>
        //                                                 )
        //                                             }
        //                                             )}
        //                                             {tier.consignee.length > 2 && <div className='tier-supplier-more-text'>{`...and ${(tier.consignee.length - 2)} more`}</div>}
        //                                         </>
        //                                     </div>

        //                                 </div>
        //                                 <Button className='learn-more-btn' onClick={() => openCustomerModal(tier)}>
        //                                     Learn More
        //                                 </Button>
        //                             </div>
        //                         )
        //                     })}
        //                 <div className='customer-map'>
        //                     <h1>Customers on map</h1>
        //                     <SupplierMapComponent details={company_details.data} company_name={company} is_customer={true} />
        //                 </div>

        //                 {isCustomerModalOpen && <CustomerModalComponent open={isCustomerModalOpen} handleClose={closeCustomerModal} tier={selectedTier} />}
        //             </div>
        //         )

        //     }

        // </>
    )
}

export default CustomerComponent;