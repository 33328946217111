// App.js
import React, { useEffect, useState } from 'react';
import MergeRequestTable from './MergeRequestTable';
import MergeRequestDetails from './MergeRequestDetail';
import MergeApprovalRequestTable from './MergeRequestTable/MergeApprovalRequests'
import { useSelector } from "react-redux";
import './index.css'
import AllMergeRequestsTableComponent from './MergeRequestTable/AllMergeRequests';
const MergeRequestDisplayScreen = ({isApprover,isRequester, current_user, showContent}) => {
    const [selectedRequest, setSelectedRequest] = useState(null);
    const handleSelectRequest = (request) => {
        setSelectedRequest(request);
      };
    
      const handleBackToRequests = () => {
        setSelectedRequest(null);
      };
      return (
        <div>
        
         {!selectedRequest && isRequester && (
            <div className='new-req'>
                <a href='/merge' target='_blank' rel="noopener noreferrer" >New Request</a>
            </div>
        )}
         <div>
                    {selectedRequest && (
                        <MergeRequestDetails
                                    isApprover={isApprover}
                                    current_user={current_user}
                                    selectedRequest={selectedRequest}
                                    onBack={handleBackToRequests}
                                    />
                    ) }
                    {/* <MergeRequestTable 
                        current_user= {current_user}
                        selectedRequest = {selectedRequest} 
                        showContent={showContent} 
                        onSelectRequest={handleSelectRequest} /> */}
                    <AllMergeRequestsTableComponent  
                        selectedRequest = {selectedRequest} 
                        onSelectRequest={handleSelectRequest} />
                    {/* <MergeApprovalRequestTable 
                        current_user= {current_user}
                        selectedRequest = {selectedRequest} 
                        showContent={showContent} 
                        onSelectRequest={handleSelectRequest} /> */}
         </div>
         <div className='about-merge'>
            <h1>Why Merge ?</h1>
            <p>
                Entity is a location of single company in the real world. It is hard to resolve entities with the large scale data we handle. 
                <br />
                With the help of below process, we can resolve entities within our system <br/>
                <ol>
                    <li> If you are eligible for submitting request, Please select related entities and fill in the new details and submit request.</li>
                    <li>Approvers can see list of all requests and approve or deny the request. Requester can't approve their own request.</li>
                    <li>while approving please review each company in the request and verify all the details in admin portal. <br/>After verifying target details of company, please approve the request</li>
                </ol> 
             </p>  
         </div>
                      
        </div>
      );
}

const MergeRequestScreen = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isRequester, setIsRequester] = useState(false);
  const [current_user, setCurrentUser] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if(currentUser && currentUser.groups) {
        var showcontent = false
        if (currentUser.groups.includes('merge_approvers')){
            setIsApprover(true);
            showcontent=true;
        }
        if(currentUser.groups.includes('merge_requesters')){
            setIsRequester(true);
            showcontent=true;
        }
        setCurrentUser(currentUser)
        setShowContent(showcontent);
    } else {
        setShowContent(false);
    }

  },[currentUser])

  return (
    <div>
        {
        showContent  && (
            <MergeRequestDisplayScreen isRequester={isRequester} isApprover={isApprover} current_user={current_user} showContent={showContent} /> 
        
        )}
                            
        { !showContent  && (
                        <h1 style={{textAlign: 'center'}}>No Permissions</h1>
                        )
        }
    </div>
    
  );
};

export default MergeRequestScreen;
