import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import "./index.css";
import {Button, Menu, MenuItem} from '@mui/material'; // Import Menu and MenuItem
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,

  Checkbox,

} from '@mui/material';
import { API_BASE } from '../../../constants';
import { axiosRequest } from '../../../services/utils/axios';
import ActionDropdown from './ActionDropdown';
const fetchData = async (page, rowsPerPage,status,search) => {
  try {
    const response = await axiosRequest.get(
      `${API_BASE}/getAllMergeRequests?limit=${rowsPerPage}&offset=${page * rowsPerPage}&status=${status}&search=${search}`
    );
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const AllMergeRequestsTableComponent = ({selectedRequest, onSelectRequest}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState("all");
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [displayData, setDisplayData] =  useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // State for Menu anchor element
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        setLoading(true);
        const response = await fetchData(page, rowsPerPage, status, search);
        setDisplayData(response.data.results)
        setTotalCount(response.data.count);
        
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndSetState();
    console.log("execured")
  }, [page, rowsPerPage, status, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  if (loading) {
    return <div className='first-steploader-container'><CircularProgress /></div>;
  }

  const cloneRequest = (row) => {
    // Redirect to the desired URL
    window.open(`/merge/${row.merge_request.id}`, '_blank');
  };
  
    const handleStatusChange = (selectedStatus) => {
        setStatus(selectedStatus);
        setAnchorEl(null); // Close the menu after selecting an option
        setPage(0);setRowsPerPage(25);
        console.log("status change", page)
    };
    
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const columns = [
        { field: 'request_id', headerName: 'Request ID', flex: 1,headerClassName: 'headerBold' , valueGetter: (params) => params.row.id,},
        { field: 'Company', headerName: 'Company', flex: 1,headerClassName: 'headerBold' , valueGetter: (params) => params.row.merge_request.company_name_english,},
          { field: 'requestedDate', headerName: 'Requested Date', flex: 1,headerClassName: 'headerBold' , valueGetter: (params) => params.row.request_date,},
          { field: 'status', headerName: 'Request Status', flex: 1, headerClassName: 'headerBold', valueGetter: (params) => params.row.merge_status},
          { field: 'selectedCompanies', headerName: 'Number of comapnies', flex: 1,headerClassName: 'headerBold' , valueGetter: (params) => params.row.merge_request?.location_ids_to_merge_count,},
          {
            field: 'action',
            headerName: '',
            headerClassName: 'headerBold',
            flex: 1,
            renderCell: (params) => (
              <ActionDropdown onSelectRequest={onSelectRequest} cloneRequest={cloneRequest} row={params.row} />
            ),
          },
        ];

    const handleSearchClick = () => {
          // Handle search logic here (e.g., fetch data, update UI)
          console.log('Search clicked:', searchTerm);
          setSearch(searchTerm);
          setPage(0);setRowsPerPage(25);

    };
      
    const handleChange = (event) => {
          setSearchTerm(event.target.value);
    };
  return (
    <>
    <div>
    
    {
      (!selectedRequest && displayData.length>0) && (
        <div>
          <h2>Merge Requests</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>
                  <input
                      type="text"
                      value={searchTerm}
                      onChange={handleChange}
                      placeholder="Search..."
                      style={{ flex: 1, padding: '5px', marginRight: '10px' }} // Adjust styles as needed
                  />
                  <button onClick={handleSearchClick}>Search</button>
              </div>
              <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-end', marginBottom: '1rem', marginRight: '3rem'}}>
                  <p style={{paddingTop: '2px',marginRight: '3px', fontWeight: 'bolder'}}>Merge Status</p>
                    <Button aria-controls="status-menu" aria-haspopup="true" onClick={handleMenuOpen}>
                      {status === "all" ? "All" : status === "completed" ? "Completed" : "Pending"}
                    </Button>
                    <Menu
                      id="status-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleStatusChange("all")}>All</MenuItem>
                      <MenuItem onClick={() => handleStatusChange("completed")}>Completed</MenuItem>
                      <MenuItem onClick={() => handleStatusChange("pending")}>Pending</MenuItem>
                    </Menu>
              </div>
          </div>
          
          {(displayData.length===0) && (
            <h4 style={{textAlign: 'center'}}> No Requests</h4>
          )}
          <div style={{margin: '1rem'}}>
              <Paper>
                  <TableContainer component={Paper}>
                          <Table>
                              <TableHead>
                                  <TableRow>
                                      {columns.map((column) => (
                                          <TableCell key={column.field} className={column.headerClassName}>
                                              {column.headerName}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {displayData.map((row) => (
                                      <TableRow key={row.id}>
                                          {columns.map((column) => (
                                              <TableCell key={column.field}>
                                                  {column.renderCell ? column.renderCell({ row }) : column.valueGetter({ row })}
                                              </TableCell>
                                          ))}
                                      </TableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                      <TablePagination
                      rowsPerPageOptions={[5,25, 35, 45, 75, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />
              </Paper>
          </div>
        </div>                          
      )
    }
    </div>
    </>
    
  );
};

export default AllMergeRequestsTableComponent;