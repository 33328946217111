import React from 'react';
import './index.css';
import { DASHBOARD_SUMMARY_TEMPLATE } from '../../../constants';
import { SUMMARY_NOTE } from '../../../constants';
const DashboardSummaryComponent = ({entityName, shipmentCount,locCount, firstYear,endYear }) => {
  
  const template = DASHBOARD_SUMMARY_TEMPLATE;
  const note = SUMMARY_NOTE;

  const summaryText = template
    .replace(/\[Entity Name\]/g, entityName)
    .replace('[#]', shipmentCount)
    .replace('[#loc]', locCount)
    .replace('years [first_year]',firstYear===endYear ? "year "+firstYear.toString() : "years "+firstYear.toString())
    .replace('to [end_year]', firstYear===endYear? "" : "to "+endYear.toString())

  return (
    <div className='summary-container'>
      <h4>Summary</h4>
      <p>{summaryText}</p>
      <small className="small-note">{note}</small>
    </div>
  );
};

export default DashboardSummaryComponent;
