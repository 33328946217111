import React, { useState } from 'react';
import './index.css'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import ApproveDenyAcrion from './ApproveDenyAction';
const MergeRequestDetails = ({isApprover, current_user, selectedRequest, onBack }) => {
  const [reason, setReason] = useState('');
  const handleDownload = () => {
    // Convert selected companies to CSV content

    let csvContent = "id,Name,address,latitude,longitude,suspicious,group_with,country\n";
    csvContent+=`-,"${selectedRequest?.merge_request?.company_name_english}","${selectedRequest?.merge_request?.full_address}","${selectedRequest?.merge_request?.latitude}","${selectedRequest?.merge_request?.longitude}","${selectedRequest?.merge_request?.suspicious}","${selectedRequest?.merge_request?.origin_company?.id}","${selectedRequest?.merge_request?.country?.name}"\n`

    csvContent = csvContent +selectedRequest?.merge_request?.location_ids_to_merge?.map(company =>
       `${company.id},"${company.company_name_english}","${company.full_address}","","","","",""`
       ).join("\n");

    // Create a Blob containing the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'selected_companies.csv';
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  
  return (
    <div className='requestDetailContainer'>
    <div className='header'>
        <h1>Request Details</h1>
        <button onClick={onBack}>Back to Requests</button>

    </div>
          
    <div style={{marginBottom: '1rem', marginTop: '1rem', marginRight: '0px', textAlign: 'right'}}>
        <button onClick={handleDownload}>Download Request as CSV</button>
    </div>
      <div className='details'>
      <TableContainer className='request-details-container'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>Requester Name</strong></TableCell>
                <TableCell>{selectedRequest?.requester?.username}</TableCell>
              </TableRow>
              {
            selectedRequest.approver && (
              <TableRow>
                <TableCell><strong>Approver Name</strong></TableCell>
                <TableCell>{selectedRequest?.approver?.username}</TableCell>
              </TableRow>
            )}
              <TableRow>
                <TableCell><strong>Requested Date</strong></TableCell>
                <TableCell>{selectedRequest?.request_date}</TableCell>
              </TableRow>
              {
            selectedRequest.approve_date && (
              <TableRow>
                <TableCell><strong>Approved Date</strong></TableCell>
                <TableCell>{selectedRequest?.approve_date}</TableCell>
              </TableRow>
            )}
            <TableRow>
                <TableCell><strong>Merge Status </strong></TableCell>
                <TableCell>{selectedRequest?.merge_status}</TableCell>
              </TableRow>
              </TableBody>
              </Table>
        </TableContainer>


        <h2>Selected Companies</h2>
        <ol>
          {selectedRequest?.merge_request?.location_ids_to_merge?.map((company, _) => (
            
            <li key={company.id}>
                <a href={`http://sc-investigation.rc.northeastern.edu:8090/admin/homepage/scdh_companylocationresolved/${company.id}`} target="_blank" rel="noopener noreferrer">
                {company.company_name_english} - {company.full_address}
              </a>
            </li>
          ))}
        </ol>
        <h2>Target Company details</h2>

        <TableContainer className='target-company-container'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>Company Name (English):</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.company_name_english}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Full Address:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.full_address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Street Address:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.street_address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Postal Code:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.postal_code}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>City:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Latitude:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.latitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Longitude:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.longitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Country:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.country?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Suspicious:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.suspicious ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Origin Company:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.origin_company?.company_name_english}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Group ID:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.origin_company?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>State:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.state}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Company Name (Legal):</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.company_name_legal}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Investigation Status:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.investigation_status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Source:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.source}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Investigation Notes:</strong></TableCell>
                <TableCell>{selectedRequest?.merge_request?.investigation_notes}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h2>Merge Options</h2>
          <TableContainer className='target-company-container'>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><strong>Remove Dangling Company Group</strong></TableCell>
                  <TableCell>{selectedRequest?.merge_request?.remove_dangling_origin_company ? 'True' :  'False'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Allow Suspicious Locations</strong></TableCell>
                  <TableCell>{selectedRequest?.merge_request?.allow_suspicious_locations ? 'True' :  'False'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Allow Related Locations</strong></TableCell>
                  <TableCell>{selectedRequest?.merge_request?.allow_related_locations ? 'True' :  'False'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Allow Related Company Groups</strong></TableCell>
                  <TableCell>{selectedRequest?.merge_request?.allow_related_companies ? 'True' :  'False'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
      </div>
      {
        ((selectedRequest.merge_status === 'pending' || selectedRequest.merge_status === 'failed' )) && (isApprover) && ( parseInt(current_user.id,10) !== parseInt(selectedRequest.requester.id, 10)) && (
          <ApproveDenyAcrion selectedRequest={selectedRequest}/>
         )
      } 
      
    </div>
  );
};

export default MergeRequestDetails;
