import { useState,useEffect, useRef } from "react"
import './index.css';
import {Grid, Switch, Tooltip } from "@mui/material";
import CompanyLocationsMap from "./CompanyLocationsMap";
import LocationsGridComponent from "./CompanyLocationsGrid";
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';

const CompanyLocations = ({ company, companyLocations, companyDetails }) => {
    
    const [showMap, setShowMap] = useState(false); // State to toggle between grid and map
    const handleToggleMap = () => {
        setShowMap(!showMap);
    };
    return (
        <>
            <div className='locs-map'>
                    <div className='locs-map-header-container'>
                        <div className='locs-map-header-text'>
                            Company Locations{" "}
                        </div>
                        <div style={{marginLeft: 'auto'}}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <TableChartIcon color={!showMap ? "primary" : "disabled"} />
                                </Grid>
                                <Grid item>
                                    <Switch
                                        checked={showMap}
                                        onChange={handleToggleMap}
                                        style={{
                                            color: showMap ? '#006EBE' : '#818181', // Change colors based on showMap state
                                        }}                                        
                                        inputProps={{ 'aria-label': 'toggle map' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <MapIcon color={showMap ? "primary" : "disabled"} />
                                </Grid>
                                
                            </Grid>
                        </div>
                    </div>
                    {showMap ? (
                            <CompanyLocationsMap companyDetails={companyDetails} companyLocations={companyLocations} />
                        ) : (
                            // Render your data grid component here
                            // For example, replace this comment with your data grid component
                            <LocationsGridComponent locations={companyLocations}/>
                    )}
            </div>
        </>
    );
}
export default CompanyLocations;