import React, { useState } from "react";

import "./index.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CSVParser from "./CSVParser";
import Switch from "@mui/material/Switch";
import MergeTableComponent from "./MergeTableComponent";
import axios from "axios";
import { API_BASE } from "../../../../constants";
const steps = [
  "Select companies to merge",
  "Provide target company",
  "Review and Submit",
];

const FirstStep = ({ selectedCompanies , setSelectedCompanies , handleNext, handleBack, activeStep, mergeId }) => {
  const [search, setSearch] = useState();
  //const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [selectedOption, setSelectedOption] = useState('company');
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const handleSearchButtonClick = () => {
    console.log("Search button clicked, perform fetch here");
    if (search && search.trim() !== "") {
      setSearchTrigger(!searchTrigger);
    }
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
    <CSVParser selectedCompanies={selectedCompanies} setSelectedCompanies={setSelectedCompanies}/>
    <p style={{textAlign: "center", marginTop:'20px'}}><strong>OR</strong>  </p>
      <div className="first-step-search-input-container">
        <TextField
          className="first-step-search-input"
          id="outlined-basic"
          label="Search Entities"
          variant="outlined"
          onChange={handleSearchChange}
        />
        <FormControl>
            <InputLabel id="dropdown-label">Search by</InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              value={selectedOption}
              label="Select Option"
              onChange={handleChange}
            >
              <MenuItem value="company">Company</MenuItem>
              <MenuItem value="address">Address</MenuItem>
              <MenuItem value="groupid">Group ID</MenuItem>
              <MenuItem value="locationid">Location ID</MenuItem>
            </Select>
        </FormControl>
        <Button
          variant="contained"
          className="first-step-search-button"
          onClick={handleSearchButtonClick}
        >
          Search
        </Button>
      </div>

      <div style={{ padding: "20px", display: "flex", alignItems: "center" }}>
        <h1 style={{ color: "#00094b" }}>
          {isChecked ? `${selectedCompanies.length} Selected` : "Search"}{" "}
          Results
        </h1>
        <div className="first-step-switch-component">
          <p className="first-step-switch-component-text">Show Selected Only</p>
          <div className="first-step-switch-box-container">
            <Switch
              checked={isChecked}
              onChange={handleToggle}
              inputProps={{ "aria-label": "Toggle switch" }}
              sx={{
                transform: "scale(1.5)",
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#00094b", // Blue color for the circle
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "#999999",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "30px" }}>
        {/* <p>table</p> */}
        <MergeTableComponent
          isChecked={isChecked}
          search={search}
          selectedOption={selectedOption}
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          searchStart={searchTrigger}
        />
      </div>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {/*   */}
        <Button disabled={selectedCompanies.length<2} onClick={handleNext}> 
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default FirstStep;
