import { createSlice } from "@reduxjs/toolkit";

import { getSupplierDataThunk } from "../../services/company-details-thunks";

const initialState = {
    supplier_details: {},
    loading: true
}

const supplierDetailsPageSlice = createSlice({
    name: 'supplier_details',
    initialState,
    extraReducers: {
        
        [getSupplierDataThunk.pending]:
            (state) => {
                state.loading = true
                state.supplier_details = {}
            },
        [getSupplierDataThunk.fulfilled]:
            (state, { payload },action) => {
                state.loading = false
                state.supplier_details = payload
                action = 'done'
            },
        [getSupplierDataThunk.rejected]:
            (state) => {
                state.loading = false
            },
    }

});

export default supplierDetailsPageSlice.reducer;