import React from 'react';
import './index.css';
import { CUSTOMER_SUMMARY_TEMPLATE, SUMMARY_NOTE } from '../../../constants';
const CustomerSummaryComponent = ({entityName, shipmentCount,firstYear,endYear,risk }) => {
  
  const template = CUSTOMER_SUMMARY_TEMPLATE
  const note = SUMMARY_NOTE

  const summaryText = template
    .replace('[Entity Name]', entityName)
    .replace('[#]', shipmentCount)
    .replace('years [first_year]',firstYear===endYear ? "year "+firstYear.toString() : "years "+firstYear.toString())
    .replace('to [end_year]', firstYear===endYear? "" : " to "+endYear.toString())
    .replace('[risk]',risk ? 'is' : 'is no');

  return (
    <div className='summary-container'>
        <h4>Summary</h4>
        <p>{summaryText}</p>
        <small className="small-note">{note}</small>
    </div>
  );
};

export default CustomerSummaryComponent;
