import { useState } from "react";
import "./login.css";
import { LOGIN_PAGE_HEADING, LOGIN_PAGE_PARAGRAPH } from "../../constants";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Signin from "./Signin";
import ForgotPassword from "./ForgotPassword";

import Register from "./Register";
import ResetPassword from "./ResetPassword";


import VerifyUser from "./VerifyUser";
// Import other components if needed

const Login = ({ setIsAuthenticated, tab: propTab }) => {
  const [tab, setTab] = useState(propTab || "signin");

  let componentToRender;

  switch (tab) {
    case "signin":
      componentToRender = (
        <Signin
          setIsAuthenticated={setIsAuthenticated}
          changeTab={setTab}
        />
      );
      break;
    case "register":
      componentToRender = <Register changeTab={setTab} />;
      break;
    case "verify":
        componentToRender = <VerifyUser changeTab={setTab} />;
        break;
    case "reset-password":
        componentToRender = <ResetPassword  changeTab={setTab} />
        break;
    case "forgot":
        componentToRender = <ForgotPassword  changeTab={setTab} />
        break;
    // Add cases for other tab states if needed
    default:
      componentToRender = null; // Handle default case if needed
  }

  return (
    <div className="gradient-form login-full-size">
      <MDBRow className="al-center">
        <MDBCol col="6" className="">
          {componentToRender}
        </MDBCol>

        <MDBCol col="6" className="login-full-height">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h1 class="mb-4" style={{ fontSize: "8rem" }}>
                {LOGIN_PAGE_HEADING}
              </h1>
              <p class="mt-5" style={{ fontSize: "1.5rem" }}>
                {LOGIN_PAGE_PARAGRAPH}
              </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default Login;
