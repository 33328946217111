import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants"; 

export const submit_request = async (details) => {

    console.log(details)
   
    const response = await axiosRequest.post(`${API_BASE}/addFeedback/`,details);
    
    return response;
}

