import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './index.css'
import { RISK_SPOTLIGHT_LAT_LNG } from '../../constants';

import Button from '@mui/material/Button';

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const MapComponent = ({ suspiciousCompanies }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null); // Store the map instance

  const handleZoomOut = () => {
    if (mapInstanceRef.current) {
      // Check if the map instance exists
      mapInstanceRef.current.setView([0, 0], 2.4); // Set the view to the initial position
    }
  };

  useEffect(() => {



    if (suspiciousCompanies&&suspiciousCompanies.length > 0 && !mapInstanceRef.current) {
      // Initialize the map only if there are suspicious companies and it's not already initialized

      var container = L.DomUtil.get('suspicious_map');
      if (container != null) {
        container._leaflet_id = null;
      }

      const susMap = L.map(mapRef.current).setView([35.8, 104.19], 1);

      L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
        maxZoom: 20,
      }).addTo(susMap);

      const markers = []

      suspiciousCompanies.forEach((company) => {
        if(company.latitude==null || company.longitude==null) {
          return;
        }
        const marker = L.circleMarker([company.latitude, company.longitude], {
          radius: 5,
          fillColor: 'red',
          color: 'red',
          weight: 8,
          opacity: 1,
          fillOpacity: 1,
        }).addTo(susMap);

        markers.push(marker);


        marker.bindPopup(
          `<div class="company-name"><a href="/company/${company.id}" target="_blank">${company.company_name_english}</a></div>` +
          `<div class="popup-address">Address: ${company.full_address}</div>`
        );
        
        mapInstanceRef.current = susMap;
      });

      susMap.on('zoomend', () => {
        const currentZoom = susMap.getZoom();
        const scaleFactor = currentZoom / 2;

        // Adjust marker size based on zoom level
        markers.forEach((marker) => {
          const initialRadius = 2; // Adjust the initial radius based on your preference
          marker.setRadius(initialRadius * scaleFactor);
        });
      });

      const maxBounds = [
        [-90, -180], // Southwest corner (latitude, longitude)
        [90, 180],   // Northeast corner (latitude, longitude)
    ];

    susMap.setMaxBounds(maxBounds)

    susMap.setView(RISK_SPOTLIGHT_LAT_LNG, 4);
      

    }




  }, [suspiciousCompanies]);

  return (
    <>
      {suspiciousCompanies && suspiciousCompanies.length > 0 && (          

          <div className="map-and-list-container">
              <div className='risk-spotlight-container'>
                  {/* <div className='risk-spotlight-button'>
                      <Button className="risk-spotlight-reset-button" aria-label="zoom out map to original size button" variant="outlined" startIcon={<ZoomOutMapIcon />} onClick={handleZoomOut}>
                        Reset Zoom
                      </Button>
                    </div> */}

                  <div className="suspicious_map" id="suspicious_map" ref={mapRef}></div>

              </div>
            
            <div className="suspicious-list">
              <ul>
                {suspiciousCompanies.map((company, index) => (
                  <li key={index} >
                      <div className='suspicious-cell'>
                          <strong>{company.company_name_english}</strong><br />
                          <div className='suspicious-cell-address'>
                              <LocationOnIcon color='error'/>
                              <p className='suspicious-cell-address-text'>{company.full_address}</p>
                          </div>
                          <div className='learn-more'>
                            <a href={`/company/${company.id}`} target="_blank" style={{ textDecoration: 'none', color: 'red' }}>
                              Learn More
                            </a>
                            <ArrowForwardIcon fontSize='small'/>
                          </div>
                      </div>
                </li>
                ))}
              </ul>
            </div>
        </div>
      )}

    </>



  );
};

export default MapComponent;
