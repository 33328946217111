import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import HomeScreen from './HomeScreen';

import Login from './Login/LoginPage';
import PrivateRoute from './PrivateRoute';
import NavbarComponent from './NavbarComponent';
import CompanyScreen from './CompanyScreen';
import InvestigateCompany from './InvestigateCompanyScreen';
import './index.css';

import Register from './Login/Register';
import Footer from './Footer';

import AdvancedSearchScreen from "./AdvancedSearchScreen";

import AboutScreen from "./AboutScreen";
import ContactScreen from "./ContactScreen";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUserThunk } from "../services/user-thunk";
import MergeScreen from "./MergeScreen";
import MergeRequestScreen from './MergeRequestScreen';
import VerifyUser from './Login/VerifyUser';

const DatahubApplication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const { currentUser, loading: userLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (!userLoading) {
      if (currentUser && ('username' in currentUser)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }
  }, [currentUser, userLoading]);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("user fetched again");
    dispatch(getUserThunk());
  }, []);

  return (
    <BrowserRouter>
      <div className="app-container">
       
        <main role="main">
          <div className="main-container">
            <Routes>
              <Route
                path="/login"
                element={<Login setIsAuthenticated={setIsAuthenticated} />}
              />

              <Route
                path="/reset-password"
                element={<Login setIsAuthenticated={setIsAuthenticated} tab="reset-password" />}
              />

              <Route
                path="/register"
                element={<Login setIsAuthenticated={setIsAuthenticated} tab="register" />}
              />
              <Route
                path="/user-verification"
                element={<Login setIsAuthenticated={setIsAuthenticated} tab="verify" />}
              />
              <Route
                path="/about"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                    <AboutScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/contact"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                    <ContactScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/investigate-company"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                    <InvestigateCompany />
                  </PrivateRoute>
                }
              />

              <Route
                path="/company/:companyId"
                element={
                  // <PrivateRoute isAuthenticated={isAuthenticated}>
                  <>
                    <NavbarComponent />
                    <CompanyScreen /> 
                  </>
                           
                  // </PrivateRoute>
                }
              />

              <Route
                path="/advanced-trace"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                    <AdvancedSearchScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/advanced-trace/:query"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                    <AdvancedSearchScreen />
                  </PrivateRoute>
                }
              />

             <Route
                path="/merge/:mergeId"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                      <MergeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/merge"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                      <MergeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mergerequest"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                     <NavbarComponent />
                      <MergeRequestScreen />
                  </PrivateRoute>
                }
              /> 
              <Route
                path="/"
                element={
                  // <PrivateRoute isAuthenticated={isAuthenticated}>
                      <>
                        <NavbarComponent />
                        <HomeScreen />
                        <Footer />
                      </>
                     
                  // </PrivateRoute>
                }
              />

              
              {/* <Route path="*"
                            element={
                                <PrivateRoute isAuthenticated={isAuthenticated}>
                                    <HomeScreen />
                                </PrivateRoute>
                            }
                        /> */}

              <Route path="*" element={<Navigate to="/" />} />
              {/* 
                        <Route path="/" element={isAuthenticated ? <HomeScreen /> : <Navigate to="/login" />} />
                        <Route path="/investigate-company" element={isAuthenticated ? <InvestigateCompany /> : <Navigate to="/login" />} />
                        <Route path="/company/:companyName" element={isAuthenticated ? <CompanyScreen /> : <Navigate to="/login" />} /> */}
            </Routes>
          </div>
        </main>
        
      </div>
    </BrowserRouter>
  );
};

export default DatahubApplication;