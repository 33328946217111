import React, { useEffect } from 'react';
import { useState } from 'react';

import { Paper, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import axiosRequest from 'axios';
import './index.css'

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import Slider from '@mui/material/Slider';

import { API_BASE } from '../../constants';


const InvestigateCompany = () => {

    //const { company_details, loading } = useSelector((state) => state.company_details);

    //const dispatch = useDispatch();

    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedYear, setSelectedYear] = useState(null);
    const [minYear, setMinYear] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchProducts, setSearchProducts] = useState([]);
    const [searchYearResults, setSearchYearResults] = useState([]);
    const [maxYear, setMaxYear] = useState(null);

    const nav = useNavigate()

    useEffect(() => {

        //dispatch(getAllCompaniesThunk())

    }, [])

    const handleCompanyChange = (event, newValue) => {
        // Update the selected company value when a selection is made
        const newVal = event.target.value;
        console.log(newVal)
        console.log("coming here")
        setSelectedCompany(newValue.value);
    };

    const handleProductChange = (event, newValue) => {
        // Update the selected company value when a selection is made
        const newVal = event.target.value;
        console.log(newVal)
        console.log("coming here")
        setSelectedProduct(newValue);
    };

    const handleSearchInputChange = (event,val) => {
        //const inputQuery = event?.target?.value;
        const inputQuery = val;
       
       
        
      
        // Send the API request immediately with the inputQuery
        // Replace with your actual API endpoint
        axios
          .get(`${API_BASE}/searchcompaniesNew?search=${inputQuery}`)
          .then((response) => {
            const responseData = response.data; // Assuming response.data is an array of search results
            setSearchResults(
              responseData.results.map((company) => ({ label: company, value: company }))
            ); // Update searchResults with the response data

          
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      };

    const steps = [
        {
            label: 'Select Company',
            description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
            label: 'Select timeline',
            description:
                'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'Select product',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const setYearSlider = (data) =>{
        let min = data.min;
        const max = data.max;


        let marks = []
        while(min <= max){
            marks.push({value:min , label:''+min});
            min++ ;
        }

        
        setMinYear(data.min);
        setMaxYear(data.max);
        setSelectedYear(data.min);
        setSearchYearResults(marks);
        
        console.log(searchYearResults)
        
    }

    const setYear = (event) => {
        console.log("varsha")
        console.log(event)

        const val = event.target.value
      
        setSelectedYear(val)
    }


    const handleNext = async() => {
       
        console.log(selectedCompany)
        if(activeStep == 0){
            const response = await axiosRequest.get(`${API_BASE}/loadinputsNew/?search=${selectedCompany}`);
            const data = response.data;
            setYearSlider(data);
            setSearchProducts(data.products);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleFinssh = async() => {
       nav(`/company/${selectedCompany}`);
       
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function valuetext(value) {
        return value;
    }

    

    return (
        <>


            {


                (

                    <div>
                        <div className="wd-login-container-wrapper">
                            <div className='wd-login-container'>
                                <div className='wd-add-product-header wd-form-header'>
                                    Investigate By Company
                                </div>

                                <Box sx={{ maxWidth: 400 }}>
                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        {steps.map((step, index) => (
                                            <Step key={step.label}>
                                                <StepLabel
                                                    optional={
                                                        index === 2 ? (
                                                            <Typography variant="caption">Last step</Typography>
                                                        ) : null
                                                    }
                                                >
                                                    {step.label}
                                                </StepLabel>
                                                <StepContent>
                                                {index === 0 && ( // Show Autocomplete input in Step 1
                                                     <Autocomplete
                                                     disablePortal
                                                     id="combo-box-demo"
                                                     options={searchResults}
                                                     sx={{ width: 300 }}
                                                     value={selectedCompany} // Set the selected value
                                                    
                                                     onInputChange={handleSearchInputChange}
                                                     //onSelect={handleCompanyChange}
                                                     onChange={handleCompanyChange}
                                                     
                                                     renderInput={(params) => <TextField {...params} label="Company" />}
                                                 />
                                                    )}
                                                    {index === 1 && ( // Show Autocomplete input in Step 3
                                                     <Slider
                                                     aria-label="Custom marks"
                                                     defaultValue={selectedYear}
                                                    getAriaValueText={valuetext}
                                                    step={1}
                                                    min = {minYear}
                                                    max = {maxYear}
                                                    valueLabelDisplay="auto"
                                                    onChange={setYear}
                                                    marks={searchYearResults}
                                                   />
                                                    )}
                                                    {index === 2 && ( // Show Autocomplete input in Step 3
                                                     <Autocomplete
                                                     disablePortal
                                                     id="combo-box-demo"
                                                     options={searchProducts}
                                                     sx={{ width: 300 }}
                                                     value={selectedProduct} // Set the selected value
                                                     onChange={handleProductChange} // Handle change event
                                                     renderInput={(params) => <TextField {...params} label="Product" />}
                                                 />
                                                    )}
                                                    <Box sx={{ mb: 2 }}>
                                                        <div>
                                                            {
                                                                index == 0 && (
                                                                    <Button
                                                                    variant="contained"
                                                                    onClick={handleNext}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                    
                                                                >
                                                                    Continue
                                                                </Button>
                                                                    
                                                                )
                                                            }
                                                            {
                                                                index == 1 && (
                                                                    <Button
                                                                    variant="contained"
                                                                    onClick={handleNext}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                    
                                                                >
                                                                    Continue
                                                                </Button>
                                                                    
                                                                )
                                                            }
                                                            {
                                                                index == 2 && (
                                                                    <Button
                                                                    variant="contained"
                                                                    onClick={handleFinssh}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                    
                                                                >
                                                                    Finish
                                                                </Button>
                                                                    
                                                                )
                                                            }
                                                        
                                                            <Button
                                                                disabled={index === 0}
                                                                onClick={handleBack}
                                                                sx={{ mt: 1, mr: 1 }}
                                                            >
                                                                Back
                                                            </Button>
                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {activeStep === steps.length && (
                                        <Paper square elevation={0} sx={{ p: 3 }}>
                                            <Typography>All steps completed - you&apos;re finished</Typography>
                                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                                Reset
                                            </Button>
                                        </Paper>
                                    )}
                                </Box>

                            </div>
                        </div>

                    </div >
                )
            }
        </>
    );
}

export default InvestigateCompany;