import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';

import './index.css'

const YearFilter = ({ defaultFromYear, defaultToYear, handleYearsSelection }) => {
    const rangeStart = 2007
    const rangeEnd = new Date().getFullYear()
    const yearRanges = [...Array(rangeEnd - rangeStart + 1).keys()].map(x => x + rangeStart);

    const [fromYear, setFromYear] = useState(defaultFromYear);
    const [toYear, setToYear] = useState(defaultToYear);
    useEffect(() => {
        setFromYear(defaultFromYear);
        setToYear(defaultToYear);
    }, [defaultFromYear,defaultToYear])

    const handleFromYearChange = (event) => {

        const selectedFromYear = event.target.value

        if (selectedFromYear > toYear) {
            setToYear('')
        }
        setFromYear(event.target.value)
    }

    const handleToYearChange = (event) => {
        setToYear(event.target.value)
    }

    useEffect(() => {
        handleYearsSelection(fromYear, toYear)
    }, [fromYear, toYear])

    return (
        <>
            <div className='year-drop-down'>
                <FormControl style={{ minWidth: 100 }}>
                    <InputLabel id="from-year-label">From Year</InputLabel>
                    <Select
                        labelId="from-year-label"
                        id="from-year-select"
                        value={fromYear}
                        label="From Year"
                        defaultValue={2015}
                        onChange={handleFromYearChange}
                    >
                        {
                            yearRanges.map((year) => <MenuItem key={year} value={year}>{year}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>

            <div className='year-drop-down'>
                <FormControl className='year-drop-down' style={{ minWidth: 100 }}>
                    <InputLabel id="to-year-label">To Year</InputLabel>
                    <Select
                        labelId="to-year-label"
                        id="to-year-select"
                        value={toYear}
                        label="To Year"
                        defaultValue={2015}
                        onChange={handleToYearChange}
                    >
                        {
                            yearRanges.map((year) => <MenuItem key={year} value={year} disabled={year < fromYear}>{year}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            {/* <Button
                className='apply-years-btn'
                type="button"
                sx={{ p: '10px' }}
                aria-label="apply-years"
                variant="outlined"
                onClick={() => handleYearsSelection(fromYear, toYear)}
                disabled={!fromYear || !toYear}
            >
                Apply
            </Button> */}
        </>
    )
}

export default YearFilter