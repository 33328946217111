import React, { useState, useEffect, useRef } from 'react';
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import './index.css';
import Chip from '@mui/material/Chip';
import { Button, colors } from '@mui/material';
import SupplierModalComponent from '../SupplierModalComponent';
import { getSupplierDataThunk } from '../../../services/company-details-thunks';
import SupplierMapComponent from '../SupplierMapComponent';
import Skeleton from '@mui/material/Skeleton';
import { axiosRequest } from '../../../services/utils/axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SupplierFilterComponent from '../SupplierFilterComponent';
import SupplierMapEntryComponent from '../SupplierMapComponent/MapEntryComponent';

import { API_BASE } from '../../../constants';
import SankeyEntryComponent from '../SankeyPlot/SankeyEntryComponent';

import SupplierSummaryComponent from '../Summary/SupplierSummaryComponent';
import RedactLogin from '../../RedactLogin';
import RedactLoginComponent from '../../RedactLogin/RedactLoginComponent';


const SupplierComponent = ({ company, companyNameInfo, setCompanyAddress}) => {

    const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);

    const [selectedTier, setSelectedTier] = useState();

    const openSupplierModal = (tier) => {
        setIsSupplierModalOpen(true)
        setSelectedTier(tier)
    };
    const closeSupplierModal = () => setIsSupplierModalOpen(false);

    // const { suppliersData, loading } = useSelector((state) => state.suppliersData);
    const [allSuppliersData, setAllSuppliersData] = useState()

    const [suppliersLoading, setSuppliersLoading] = useState(false)
    const [tiersName, setTiersName] = useState()
    const [currentMinYear, setCurrentMinYear] = useState(2014);
    const [currentMaxYear, setCurrentMaxYear] = useState(2014);
    const [companyName, setCompanyName] = useState(companyNameInfo)
    const { currentUser } = useSelector((state) => state.user);

    const [showLoginComponent, setShowLoginComponent] = useState(false);

    const [supplierFilters, setSupplierFilters] = useState({
        draft: {
            tier: "3", year: { from: currentMinYear, to: currentMaxYear }
        },
        applied: {
            tier: "3", year: { from: currentMinYear, to: currentMaxYear }
        },
    })
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 2;
        setCurrentMaxYear(previousYear);
        setCurrentMinYear(previousYear);
        if(currentUser && ('username' in currentUser)) {
            fetchSuppliers({ company: company, product: "ALL", startY: null, endY: null, tiers: 5, suppliers: [] })
        }
    }, [])
    useEffect(() => {

        if(currentUser && ('username' in currentUser)) {
            setShowLoginComponent(false)
            fetchSuppliers({ company: company, product: "ALL", startY: null, endY: null, tiers: 5, suppliers: [] })
        } else {
            setShowLoginComponent(true)
        }
    }, [currentUser])
    const tiersLoaded = useRef();

    
    const fetchSuppliers = async (filterQuery) => {
        try {
            setSuppliersLoading(true)
            const response = await axiosRequest.get(`${API_BASE}/loadSupplierInfo/`, 
            { 
                 params: filterQuery
            });
             // If response status is 401, redirect to login
            
            setShowLoginComponent(false);
            setCompanyAddress(response?.data?.company?.full_address);
            setCompanyName(response?.data?.company?.company_name_english)   
            setCurrentMinYear(response?.data?.startY);
            setCurrentMaxYear(response?.data?.endY);
            const filterStatus = "applied"
            const filters = {
                ...supplierFilters,
                [filterStatus]: {
                    ...supplierFilters[filterStatus],
                    year: {
                        from: response?.data?.startY,
                        to: response?.data?.endY
                    }
                }
            }
            setSupplierFilters(filters)
            const finalDictList = response?.data?.tiers?.flatMap(({ tier, shippers }) => ({ tier, shipperName: shippers.map(({ company_name_english }) => company_name_english) }));
            setAllSuppliersData(response);
            setTiersName(finalDictList)
            setSuppliersLoading(false);
            try {
                if (window.umami) {
                    let organisationType = currentUser?.organisation_type;
                    organisationType = organisationType.split(' ')[0];
                    window.umami.track('SupplierLoad '+organisationType, {
                        "searchParams": 
                        response?.data?.company?.company_name_english
                        +"/"+company+ "/"+filterQuery?.startY+"/"+filterQuery?.endY,
                    });
                } else {
                    console.log("window.umami not there");
                }
              } catch (error) {
                  console.error("An error occurred:", error);
              }
            return response;
        }
        catch (error) {
            console.log(error)
            if(error?.response?.status === 401) {
                setShowLoginComponent(true);
                // console.log(error?.response)
                // setCompanyAddress(error?.response?.data?.company?.full_address);
                // setCompanyName(error?.response?.data?.company?.company_name)
            }
            setSuppliersLoading(false)
        }
    }

    const handleFilterSelection = async (appliedFilter) => {
        const filterQuery = {
            company: company,
            product: "ALL",
            startY: appliedFilter.year.from,
            endY: appliedFilter.year.to,
            tiers: "5"
        }
        setCurrentMinYear(appliedFilter.year.from);
        setCurrentMaxYear(appliedFilter.year.to);
        
        await fetchSuppliers(filterQuery)

    }

    const [suspiciousOnly, setSuspiciousOnly] = useState(false)

    const setSus = () => {
        setSuspiciousOnly((prevSuspiciousOnly) => {
            // You can perform additional actions here if needed

            // Update the state and return the new value
            return !prevSuspiciousOnly;
        });

    };




    return (
        <>
            {suppliersLoading && <><Grid container wrap="nowrap">
                {Array.from(new Array(2)).map((item, index) => (
                    <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                        <Skeleton variant="rectangular" width={"90%"} height={320} />
                    </Box>
                ))}
            </Grid>
                <Grid container wrap="nowrap">
                    {Array.from(new Array(2)).map((item, index) => (
                        <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                            <Skeleton variant="rectangular" width={"90%"} height={320} />
                        </Box>
                    ))}
                </Grid></>}
            



            <div className='supplier-main-container'>
                <div className='supplier-component-filter-container'>
                    <SupplierFilterComponent
                        supplierFilters = {supplierFilters} 
                        setSupplierFilters = {setSupplierFilters}
                        numberOfTiers={allSuppliersData?.data.tiers.length}
                        countries={[...new Set(allSuppliersData?.data.tiers.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.supplier_countries.split(",")], []))]}
                        tierNames={tiersName}
                        handleFilterSelection={handleFilterSelection}
                    ></SupplierFilterComponent>
                </div>
                {
                !suppliersLoading && !showLoginComponent && !allSuppliersData?.data?.has_suppliers && (
                    <>
                        <div className='no-suppliers'>
                            No Suppliers Found based on filter parameters
                        </div>
                    </>
                  )
                 }
                 {
                    showLoginComponent && (
                        <RedactLoginComponent companyName={companyName} />
                    )
                 }
                {!suppliersLoading && !showLoginComponent && allSuppliersData?.data?.has_suppliers && (
                    
                    <div className='supplier-data-container' >
                    <div >
                        <SupplierSummaryComponent entityName={allSuppliersData?.data?.company.company_name_english} shipmentCount={allSuppliersData?.data?.tiers[0]?.shippers?.length} firstYear={currentMinYear} endYear={currentMaxYear} risk={allSuppliersData?.data?.sus_relations[0]?.length>0} />
                    </div>
                    <SupplierMapEntryComponent allSuppliersData={allSuppliersData} tiersName = {tiersName} company_name={companyName} handleFilterSelection={handleFilterSelection} is_customer={false} />
                   
                    <SankeyEntryComponent allSuppliersData={allSuppliersData} tiersName = {tiersName} company_name={companyName} handleFilterSelection={handleFilterSelection} is_customer={false} start_year={currentMinYear} end_year={currentMaxYear}/>
                        <div className='tier-container'>
                            {
                                allSuppliersData?.data.tiers.map((tier, index) => {
                                    return (
                                        <div className={`tier-card ${tier.suspicious_suppliers ? 'tier-card-red' : ''}`} key={index}>
                                            <div className={`tier-number ${tier.suspicious_suppliers ? 'tier-number-red' : ''}`}> {`Tier ${tier.tier} `}
                                            {tier.suspicious_suppliers &&(
                                                <Tooltip
                                                title="Connections to High-Risk Entities are found in this tier"
                                                placement="right-end"
                                                arrow
                                                style={{ marginLeft: '10px' }}
                                                >
                                                <InfoIcon></InfoIcon>
                                                </Tooltip>                                                 
                                            ) }
                                                
                                             
                                             </div> 
                                            {tier.supplier_countries.length>0 && (
                                                <div className='tier-countries-container'>
                                                    <div className='tier-countries-header'>
                                                        Countries
                                                    </div>
                                                    <div className='tier-countries'>
                                                        <>
                                                            {
                                                                tier.supplier_countries.split(",").slice(0, 20).map((country, index) => {
                                                                    return (
                                                                        <Chip className='tier-country' label={country} color="primary" variant="filled" key={country}></Chip>
                                                                    )
                                                                }
                                                                )}
                                                        </>{
                                                            ((tier.supplier_countries.split(",").length - 20) > 0) && <Chip className='tier-country' label={(`+${tier.supplier_countries.split(",").length - 20}`)} color="primary" >

                                                            </Chip>}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='tier-suppliers-container' style={{ paddingTop: tier.supplier_countries.length === 0 ? '30px' : '10px' }}>
                                                <div className='tier-suppliers-header'>
                                                    Suppliers
                                                </div>
                                                {/* {tier.suspicious_suppliers && (
                                                    <p style={{'color': 'red', 'font-size': 'smaller','font-weight': 'bolder','marginTop': '0px'}}> Connections to High-Risk Entities are found in this tier </p>
                                                )} */}
                                                <div className='tier-suppliers'>
                                                    <>
                                                        {tier.shippers.slice(0, 2).map(({ company_name_english, full_address, suspicious }) => {
                                                            return (
                                                                <div className='tier-supplier-container' key={company_name_english}>
                                                                    <div style={{'color': suspicious ? 'red' : 'black' }}>
                                                                        {company_name_english}
                                                                    </div>
                                                                    <div className='tier-supplier-address'>
                                                                        {full_address}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                        {tier.consignee.length > 2 && <div className='tier-supplier-more-text'>{`...and ${tier.shippers.length > 2 && (tier.shippers.length - 2)} more`}</div>}
                                                    </>
                                                </div>

                                            </div>
                                            <Button className='learn-more-btn' aria-label={`Click this button to learn more about the ${tier.tier} tier`} onClick={() => openSupplierModal(tier)}>
                                                Learn More
                                            </Button>
                                        </div>
                                    )
                                })}


                            {isSupplierModalOpen && <SupplierModalComponent open={isSupplierModalOpen} handleClose={closeSupplierModal} tier={selectedTier} />}
                        </div>
                    </div>
                )}
            </div >

        </>
    )
}

export default SupplierComponent;