import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  if (!isAuthenticated) {
    localStorage.setItem('redirectPath', window.location.pathname);
  }
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
  // return (
  //   <Route
  //     {...rest}
  //     element={isAuthenticated ? <Component /> : navigate('/login')}
  //   />
  // );
};

export default PrivateRoute;