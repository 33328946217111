// import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants"; 
import axios from "axios";

export const get_details = async () => {

    const response = await axios.get(`${API_BASE}/dashboardNew/`);

    return response;
}
