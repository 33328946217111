import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import "./index.css";
import {Button} from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,

  Checkbox,

} from '@mui/material';
import { API_BASE } from '../../../../constants';
const fetchData = async (page, rowsPerPage, search, selectedOption) => {
  try {
    const response = await axios.get(
      `${API_BASE}/mergesearchcompanies?limit=${rowsPerPage}&offset=${page * rowsPerPage}&search=${search}&searchBy=${selectedOption}`
    );
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const MergeTableComponent = ({isChecked,searchStart, selectedOption, search, selectedCompanies, setSelectedCompanies, mergeId}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [displayData, setDisplayData] =  useState([]);
  const handleSelectAllClick = () => {
      const updatedSelectedCompanies = [
        ...selectedCompanies,
        ...displayData.filter(row => !selectedCompanies.some(company => company.id === row.id)),
      ]
      setSelectedCompanies(updatedSelectedCompanies);
  };
  const handleDeSelectAllClick = () => {
    const updatedSelectedCompanies = selectedCompanies.filter(
      company => !displayData.some(row => row.id === company.id)
    );
    setSelectedCompanies(updatedSelectedCompanies);
  };
  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        setLoading(true);
        if(search) {
          console.log("doing call");
          const response = await fetchData(page, rowsPerPage, search, selectedOption);
          // setData(response.data.results);
          console.log(response,"looks");
          setDisplayData(response.data.results)
          // setTotalPages(Math.ceil(response.data.count / rowsPerPage));
          setTotalCount(response.data.count);
        } 
        
      } finally {
        setLoading(false);
      }
    };
    if(!isChecked) {
      console.log("doing external")

      fetchDataAndSetState();
    } else {
      console.log("doing internal")
      const startIndex = (page) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      const selectedCompaniesOnPage = selectedCompanies.slice(startIndex, endIndex);

      if(selectedCompaniesOnPage.length===0 && selectedCompanies.length>0) {
        setPage(0);
      } else {
        selectedCompaniesOnPage.sort((a, b) => a.id - b.id);
        setDisplayData(selectedCompaniesOnPage);
        // setTotalPages(Math.ceil(selectedCompanies.length / rowsPerPage));
        setTotalCount(selectedCompanies.length);
      }
      
    }
    
  }, [page, rowsPerPage, searchStart, isChecked]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = row => {
    setSelectedCompanies(prevSelectedCompanies => {
      if (prevSelectedCompanies.some(company => company.id === row.id)) {
        return prevSelectedCompanies.filter(selected=> selected.id !== row.id);
      } else {
        return [...prevSelectedCompanies, row];
      }
    });
  };
  if (loading) {
    return <div className='first-steploader-container'><CircularProgress /></div>;
  }

  return (
    <>
    {displayData?.length>0 && 
    <div className='select-button-group'>
          <Button variant="contained" 
              color='primary'
              style={{marginBottom: '1rem', marginTop: '0px'}}
              onClick={handleSelectAllClick}>
                    Select All companies in page
          </Button>
          <Button variant="contained" 
              color= 'error' 
              style={{marginBottom: '1rem', marginTop: '0px'}}
              onClick={handleDeSelectAllClick}>
                    Deselect All companies in page
          </Button>
    </div>
      
    }
    {(displayData.length===0) && (
      <h4 style={{textAlign: 'center'}}> No Results {isChecked ? 'Selected' : 'Found'}</h4>
    )}
    {
      displayData.length>0 && (
      <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell><strong>Location ID</strong></TableCell>
                    <TableCell><strong>Group ID</strong></TableCell>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Address</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayData && displayData.map(row => (
                    <TableRow key={row.id}>
                    <TableCell>
                        <Checkbox
                          checked={selectedCompanies && selectedCompanies.some(company => company.id === row.id)}
                          onChange={() => handleCheckboxChange(row)}
                        />
                      </TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row?.origin_company?.id}</TableCell>
                      <TableCell>{row.company_name_english}</TableCell>
                      <TableCell>{row.full_address}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5,25, 35, 45, 75, 100, 150]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Paper>
      )
    }
    </>
  );
};

export default MergeTableComponent;