import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants"; 

export const get_company_details = async (details) => {
    console.log("---test")

    console.log(details)
   
    const response = await axiosRequest.get(`${API_BASE}/loadcompanyinfoNew/${details.company}/${details.year}/${details.productName}/`);
    
    return response;
}


export const get_all_companies = async () => {
    console.log()
    const response = await axiosRequest.get(`${API_BASE}/getAllCompaniesNew/`);
    
    console.log("LOGGING")
    console.log(response)
    return response;
}


export const get_investigate_company_results = async (input) => {

    const response = await axiosRequest.get(`${API_BASE}/loadInputsNew`);
    
    console.log("LOGGING")
    console.log(response)
    return response;

}

export const get_supplier_data = async (details) => {

    console.log("--JAYARAM")

    const response = await axiosRequest.post(`${API_BASE}/loadSupplierInfo/`,details);
   
    return response;

}


export const get_customer_data = async (details) => {

    const response = await axiosRequest.post(`${API_BASE}/loadCustomerInfo/`,details);
    
    console.log("LOGGING")
    return response;

}