// CustomPieChart.js

import React from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend,Label } from 'recharts';
import { Paper, Typography } from '@material-ui/core';

const CustomPieChart = (products_received) => {
    const calculatePercentage = (value, total) => ((value / total) * 100).toFixed(2) + '%';

    // const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#9C27B0'];
    const COLORS = ['#BA361A', '#FFC300', '#3498db', '#FF5733', '#2ecc71', '#9b59b6', '#16a085'];

    const products = products_received.map(entry => ({
        ...entry,
        percentage: calculatePercentage(entry.count, products_received.reduce((sum, entry) => sum + entry.count, 0)),
    }));

    if (!products || products.length === 0) {
        return <div>No data available</div>;
    }

    const total = products.reduce((sum, entry) => sum + entry.count, 0);
    const CustomTooltip = ({ payload }) => {
        if (!payload || payload.length === 0) {
            return null;
        }
    
        const entry = payload[0].payload;
    
        return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', maxWidth: '230px', borderRadius: '5px' }}>
           <Typography variant="h7">
                <span style={{ fontWeight: 'bold' }}>{entry.product}</span> <br/><br/>
            </Typography>    
            <Typography variant="body1">
                {/* <span style={{ fontWeight: 'bold' }}>{entry.product}</span> <br/> */}
                <span style={{ fontWeight: 'bold' }}>Transaction Count</span>: {entry.count}<br/>
                <span style={{ fontWeight: 'bold' }}>percentage</span>:{entry.percentage}<br/>
            </Typography>
        </div>
        );
    };
    const getRandomColor = () => `#${Math.floor(Math.random()*16777215).toString(16)}`;

  return (
    <Paper style={{ padding: 20, marginTop: 20 }}>
      <PieChart width={400} height={400}>
        <Pie
          data={products.map(entry => ({ ...entry, total }))}
          dataKey="count"
          nameKey="product"
        //   cx="50%"
        //   cy="50%"
          fill="#8884d8"
          
        >
          {products.map((entry, index) => (
            <Cell key={index} fill={getRandomColor()} />
          ))}
        </Pie>
        <Tooltip
            content={<CustomTooltip />} 
        />
        {/* <Legend
          payload={products.map((entry, index) => ({
            value: ` ${entry.hscode}`,
            type: 'rect',
            color: COLORS[index % COLORS.length],
          }))}
        /> */}
      </PieChart>
    </Paper>
  );
};

export default CustomPieChart;
