import React from "react";
import InfoCardComponent from "./InfoCard";
import {
  SUPPLYTRACE_INFO_CONTENT,
  SUPPLYTRACE_INFO_HEADING,
  SUPPLYTRACE_CARD1_CONTENT,
  SUPPLYTRACE_CARD1_HEADING,
  SUPPLYTRACE_CARD2_CONTENT,
  SUPPLYTRACE_CARD2_HEADING,
  SUPPLYTRACE_CARD3_HEADING,
  SUPPLYTRACE_CARD3_CONTENT,
  SUPPLYTRACE_CARD1_IMG,
  SUPPLYTRACE_CARD2_IMG,
  SUPPLYTRACE_CARD3_IMG
} from "../../constants";

const SupplyTraceInfoComponent = () => {
  return (
    <div className="supplytrace-info-container">
      <div className="supplytrace-info-heading">
        <div className="supplytrace-info-header">
          {SUPPLYTRACE_INFO_HEADING}
        </div>
        <div className="supplytrace-info-content">
          {SUPPLYTRACE_INFO_CONTENT}
        </div>
      </div>

      <div className="supplytrace-info-card">
        <InfoCardComponent img={SUPPLYTRACE_CARD1_IMG} pos="top" heading={SUPPLYTRACE_CARD1_HEADING} text = {SUPPLYTRACE_CARD1_CONTENT} color="rgb(0,98,178)"/>
        <InfoCardComponent img={SUPPLYTRACE_CARD2_IMG} pos="left" heading={SUPPLYTRACE_CARD2_HEADING} text = {SUPPLYTRACE_CARD2_CONTENT} color="rgb(248,25,65)"/>
        <InfoCardComponent img={SUPPLYTRACE_CARD3_IMG} pos="left" heading={SUPPLYTRACE_CARD3_HEADING} text = {SUPPLYTRACE_CARD3_CONTENT} color="rgb(253,102,45)" />
      </div>
    </div>
  );
};

export default SupplyTraceInfoComponent;
