import React from "react";
import Chip from "@mui/material/Chip";

const ThirdStepTargetCompanyDisplay = ({ formData }) => {
  const {
    entityNameEnglish,
    entityNameLegal,
    country_name,
    origin_company_name,
    latitude,
    longitude,
    address,
    // entityType,
    investigationStatus,
    // internalNotes,
    // investigationNotes,
  } = formData;
  console.log(formData,"fdfs");
  return (
    <div className="third-step-target-display-container">
      <div className="third-step-target-display-header">
        {entityNameEnglish}
      </div>

      <div className="third-step-target-display-data-container">
        <div className="third-step-target-display-data mb-2rem">
          <span className="third-step-target-display-label">
          Legal Name    
          </span>
          - {entityNameLegal}
        </div>

        <div className="third-step-target-display-data">
          {latitude}&deg; Lat | {longitude}&deg; Long
        </div>

        <div className="third-step-target-display-data mb-2rem">
          {address}
        </div>
       

        <div className="third-step-target-display-data mb-2rem">
          <span className="third-step-target-display-label">
          Group with
          </span>
          - {origin_company_name}
        </div>

        <div className="third-step-target-display-data">
          <Chip
            className="tier-country"
            label={`Country | ${country_name}`}
            color="primary"
            variant="filled"
            key={country_name}
            tabIndex={0}
          ></Chip>
           {/* <Chip
            className="tier-country"
            label={`Type | ${entityType}`}
            color="primary"
            variant="filled"
            key={entityType}
            tabIndex={0}
          ></Chip> */}
           {/* <Chip
            className="tier-country"
            label={`Status | ${investigationStatus}`}
            color="primary"
            variant="filled"
            key={entityType}
            tabIndex={0}
          ></Chip> */}
        </div>

       
      </div>
    </div>
  );
};

export default ThirdStepTargetCompanyDisplay;
