import React from "react";
import "./index.css";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Chip from "@mui/material/Chip";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

const SupplierModalComponent = ({ open, handleClose, tier }) => {
  const columns = [
    {
      field: "company_name_english",
      headerName: "Supplier",
      width: 500,
      renderHeader: () => (
        <div className="tier-supplier-table-header-modal">{"Supplier "}</div>
      ),
      renderCell: (params) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={params.id}>
            <div tabIndex={0}>{params.value}</div>
          </a>
        );
      },
    },
    {
      field: "full_address",
      headerName: "Address",
      flex: 1,
      headerClassName: "tier-supplier-table-header-modal",
      minWidth: 400,
      renderHeader: () => (
        <div className="tier-supplier-table-header-modal">{"Address "}</div>
      ),
      renderCell: (params) => {
        return <div tabIndex={0} className="hide-scroll">{params.value}</div>;
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <div className="supplier-modal-container">
          <div className="tier-card-modal">
            <main>
            <div className="tier-number"> {`Tier ${tier.tier}`}</div>
            {tier.supplier_countries.length>0 && (<div className="tier-countries-container">
              <div className="tier-countries-header">Countries</div>
              <div className="tier-countries">
                <>
                  {tier.supplier_countries?.split(",").map((country) => {
                    return (
                      <Chip
                        className="tier-country"
                        label={country}
                        color="primary"
                        variant="filled"
                        key={country}
                        tabIndex={0}
                      ></Chip>
                    );
                  })}
                </>
              </div>
            </div>)}
            <div className="tier-suppliers-container-modal" style={{ paddingTop: tier.supplier_countries.length === 0 ? '30px' : '10px' }}>
              <div className="tier-suppliers-header" tabIndex={0}>
                Suppliers
              </div>

              <div className="tier-suppliers-modal">
                <>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      // owHeight={95}
                      rows={tier.shippers.map((item, index) => ({
                        id: index + 1, // Add a numerical ID
                        ...item, // Spread the existing properties
                      }))}
                      columns={columns}
                      components={{ Toolbar: CustomToolbar }}
                      autoHeight="true"
                      density="compact"
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                    />
                  </div>
                </>
              </div>
            </div>
            </main>
            
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SupplierModalComponent;
