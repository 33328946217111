import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../Footer';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SupplierComponent from './SupplierComponent';
import CustomerComponent from './CustomerComponent';

import { axiosRequest } from '../../services/utils/axios';
import { API_BASE } from '../../constants';
import { useSelector } from "react-redux";


import './index.css';
import CustomTabPanel from '../CustomTabPanel';
import AboutComponent from './AboutComponent';
import CompanyLeftPanel from './CompanyLeftPanel';
import { useLocation } from "react-router-dom";

const CompanyScreen = () => {

    const { companyId } = useParams();
    const [ companyAddress, setCompanyAddress ] = useState("");
    const [ companyName, setCompanyName ] = useState("");
    const { currentUser } = useSelector((state) => state.user);
    const { hash } = useLocation();
    useEffect(()=> {
        if(companyName.length > 0){
            document.title = companyName;
        }
    },[companyName]);
    useEffect(() => {


        fetchCompanyInfo()

        
    }, [companyId]);

    const fetchCompanyInfo = async () => {
        try {
            const response = await axiosRequest.get(`${API_BASE}/getinfo/?id=${companyId}`);
            setCompanyName(response?.data?.company_name_english)
            setCompanyAddress(response?.data?.full_address)
            try {
                if (window.umami) {
                    let organisationType = currentUser?.organisation_type ?? "unknown";
                    organisationType = organisationType.split(' ')[0];
                    window.umami.track('companyLoad#'+organisationType, {
                        "company": response?.data?.company_name_english + " (" + companyId+ ")",
                    });
                } else {
                    console.log("window.umami not there");
                }
              } catch (error) {
                  console.error("An error occurred:", error);
              }
        }
        catch (error) {
            console.log(error)
        }
    }
    const renderMainContent = () => {
        switch (hash) {
            case "#supplier":
                return (
                <SupplierComponent company={companyId} companyNameInfo={companyName} setCompanyAddress={setCompanyAddress} />
                )
            case "#customer":
                return <CustomerComponent company={companyId} companyNameInfo={companyName} setCompanyAddress={setCompanyAddress}/>
            default:
                return <AboutComponent company={companyId} companyName={companyName} setCompanyAddress={setCompanyAddress} />
        }
    }

    return (
        <div className='customer-container'>
            <div className='customer-left-container'>
                <CompanyLeftPanel companyName={companyName} companyAddress = {companyAddress}/>
            </div>

            <div className='customer-display-container'>
                {renderMainContent()}
                <Footer />
            </div>
        </div>

    )
}

export default CompanyScreen;
