import React from "react";
import { useState } from "react";

import { Grid, TextField, Button } from "@mui/material";
import "./login.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { validateToken } from "../../services/login-service";
import { resetPassword } from "../../services/login-service";
import { MDBBtn } from "mdb-react-ui-kit";

import LoginIcon from "@mui/icons-material/Login";

const ResetPassword = ({ changeTab }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const userId = searchParams.get("id");
  
    const [isValidToken, setIsValidToken] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordInput, setPasswordInput] = useState({
      password: "",
      retypePassword: "",
    });
  
    useEffect(() => {
      const verifyToken = async () => {
        try {
          await validateToken(token, userId);
          setIsValidToken(true);
        } catch (error) {
          setErrorMessage(
            error.response?.data.message || "Invalid token or user ID"
          );
        } finally {
          setLoading(false);
        }
      };
  
      verifyToken();
    }, [token, userId]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (!isValidToken) {
      return <div style={{textAlign: "center"}}>
          <p style={{color: "red", margin: "15px"}}>{errorMessage}</p>
          <Button  onClick={ () => { changeTab('forgot')}}> Forgot Password</Button>
      </div>;
    }
  
    const handleInput = (event) => {
      const { name, value } = event.target;
      setPasswordInput({
        ...passwordInput,
        [name]: value,
      });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const { password, retypePassword } = passwordInput;
  
      if (password !== retypePassword) {
        setErrorMessage("Passwords do not match.");
        return;
      }

      const resetData = {
        token,
        userId,
        newPassword: password,
      };
  
      try {
        await resetPassword(resetData);
        // Handle success - you might want to redirect the user or show a success message
        changeTab("signin")
      } catch (error) {
        setErrorMessage(
          error.response?.data.message || "Failed to reset password"
        );
      }
    };

  return (
    <div className="d-flex flex-column ms-5">
      <div className="login-header">
        <img width={600} src="logo/ST_OneLine_RGB.png" alt="our logo"></img>
        <h3 className="homepage-logo-tagline">
          Open supply chain intelligence for a just world
        </h3>
      </div>
      <div
        style={{ textAlign: "left", marginBottom: "1.5rem", color: "#0062b2" }}
      >
        <h4>Reset Password</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                fullWidth
                required
                aria-label="username text box"
                name="password"
                type="password"
                label="New Password"
                onChange={handleInput}
              />
            }
          </Grid>
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                required
                fullWidth
                aria-label="password text box"
                name="retypePassword"
                label="Retype Password"
                type="password"
                onChange={handleInput}
              />
            }
          </Grid>

          <Grid item xs={12} className="login-full-width">
          {
            errorMessage && (
                  <p style={{color: "red", marginTop: "15px"}}>{errorMessage}</p>
                )
          }

            <Button
              className="wd-submit-btn-login"
              startIcon={<LoginIcon />}
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </form>
    
      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mt-4">
        <p className="mb-0">Don't have an account?</p>
        <MDBBtn outline className="mx-2" color="danger">
          Register
        </MDBBtn>
      </div>
    </div>
  );
  
};

export default ResetPassword;
