import './App.css';
import DatahubApplication from './sc-datahub';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";
import userReducer from './sc-datahub/Reducers/user-reducer';
import homepageReducer from './sc-datahub/Reducers/homepage-reducer';
import companyDetailsReducer from './sc-datahub/Reducers/company-details-reducer';

import supplierDetailsReducer from './sc-datahub/Reducers/supplier-details-reducer';
import customerDetailsReducer from './sc-datahub/Reducers/customer-details-reducer';
import aboutPageReducer from './sc-datahub/Reducers/about-page-reducer';

const store = configureStore(
  {
      reducer:
      {
          user: userReducer,
          details : homepageReducer,
          company_details : companyDetailsReducer,
          supplier_details: supplierDetailsReducer,
          customer_details: customerDetailsReducer,
          company_info : aboutPageReducer

      }
  });


function App() {
  return (
    <Provider store={store}>
        <DatahubApplication></DatahubApplication>
    </Provider>
  );
}

export default App;
