import React from "react";

import StepperComponent from "./StepperComponent";
import { useParams } from 'react-router-dom';

import "./index.css";

const MergeScreen = () => {
  const { mergeId } = useParams();

  return (
    <div className="merge-screen-container">
      <div className="merge-screen-header">
        <h1>Merge Companies</h1>
      </div>
      <div className="merge-screen-stepper">
       <StepperComponent mergeId = {mergeId}></StepperComponent>
      </div>
    </div>
  );
};

export default MergeScreen;
