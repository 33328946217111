import React from "react";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { TAG_LINE } from "../../constants";
import { Grid, TextField, Button } from "@mui/material";
import "./login.css";

import { MDBBtn } from "mdb-react-ui-kit";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constants";
import axios from 'axios';
import { API_BASE } from "../../constants";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";
import { FormControl, InputLabel, MenuItem, Select ,Checkbox, FormControlLabel} from '@mui/material';
import { OutlinedInput } from '@mui/material';

const organizationTypes = [
  "Academic / Researcher / Journalist / Student",
  "Auditor / Certification Scheme / Service Provider",
  "Brand / Retailer / Company",
  "Civil Society Organization",
  "Government",
  "Law Enforcement",
  "Multi-Stakeholder Initiative",
  "Trade / Compliance",
  "Other"
];
const Register = ({ changeTab }) => {
  const [loginLoading, setLoginLoading] = useState(false);

  const [loginInput, setLoginInput] = useState({});

  const [passwordMatch, setPasswordMatch] = useState(true); 
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
  const [captchaValue, setCaptcha] = useState('');
  const navigate = useNavigate();

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    
    setLoginInput(prevState => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const validateInputs = () => {
    
    if (loginInput.password !== loginInput['retype password']) {

      setPasswordMatch(false)
    
      return false;
    }
  
    return true;
  };

  const onChangeCaptcha = (value) => {
    setCaptcha(value)
    setIsCaptchaValidated(true);
  }
  const [errorMessage, setErrorMessage] = useState('');

  const registerUser = async () => {
    try {
      const registrationData = {
        username: loginInput['username'],
        email: loginInput['email'],
        password: loginInput['password'],
        organisation_type: organizationType!=='Other' ? organizationType : otherOrganization,
        'g-recaptcha-response': captchaValue,
        'accept_tandc': isTermsAccepted
      };
      const response = await axios.post(`${API_BASE}/register/`, registrationData);
      setVerify();
      console.log(response.data);
    } catch (error) {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      setErrorMessage(error.response.data);  // Set error message state

    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (validateInputs()) {
      try {
        setLoginLoading(true);
        await registerUser();
      } catch (error) {
      } finally {
        setLoginLoading(false);
      }
    }
  };
  const [organizationType, setOrganizationType] = useState('Academic / Researcher / Journalist / Student');
  const [otherOrganization, setOtherOrganization] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleOrganizationTypeChange = (event) => {
    setOrganizationType(event.target.value);
  };
  const handleTermsCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };
  const handleOtherOrganizationChange = (event) => {
    setOtherOrganization(event.target.value);
  };
  const recaptchaRef = useRef(null);

  const setSignIn = (event) => {
    changeTab("signin");
  };
  const setVerify = (event) => {
    changeTab("verify");
  };

  return (
    <div className="d-flex flex-column ms-5">
      <div className="text-center">
        <img
          className="login-page-logo"
          src="logo/ST_Stacked_RGB.png"
          alt="contributor1 profile"
          tabIndex={0}
        ></img>
        <h4 className="mt-1 mb-5 pb-1 login-page-logo-tagline">{TAG_LINE}</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="row" spacing={3}>
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                fullWidth
                required
                aria-label="username text box"
                name="username"
                type="email"
                label="Email"
                onChange={handleInput}
                inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$" }}
              />
            }
          </Grid>
          <Grid item xs={6} className="wd-login-item">
            <div>
              <FormControl fullWidth>
                <InputLabel id="organization-type-label">Organization Type</InputLabel>
                <Select
                  labelId="organization-type-label"
                  id="organization-type"
                  value={organizationType}
                  input={<OutlinedInput notched label={'Organization Type'} />}
                  onChange={handleOrganizationTypeChange}
                  fullWidth
                >
                  {organizationTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              
            </div>
          </Grid>
          
          {organizationType === 'Other' && (
              <Grid className="other-field-css">
                <TextField
                      id="other-organization"
                      label="Other organization type"
                      value={otherOrganization}
                      onChange={handleOtherOrganizationChange}
                      fullWidth
                      required
                      helperText="Please specify"
                      inputProps={{ maxLength: 100 }}
                    />
                </Grid>
              )}
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                required
                fullWidth
                aria-label="password text box"
                name="password"
                label="Password"
                type="password"
                onChange={handleInput}

              />
            }
          </Grid>
          <Grid item xs={6} className="wd-login-item">
            {
              <TextField
                required
                fullWidth
                aria-label="password text box"
                name="retype password"
                label="Re-type password"
                type="password"
                onChange={handleInput}
                error={!passwordMatch}
              />
            }
          </Grid>

          <div
            style={{
              display: passwordMatch ? 'none' : 'block',
              color: 'red',
              marginTop: '8px',
            }}
          >
            Passwords do not match
          </div>
         <p style={{margin: '0 1.5rem', fontSize: '13px'}}>
         Password should be at least 8 characters long, include one capital letter, and one special character.
         </p>

          <Grid item xs={12} className="login-full-width">
            {/* <Button className='wd-submit-btn' variant="contained">
                        Login
                    </Button> */}
          <Grid item xs={12} className="wd-login-item">
            {
             <ReCAPTCHA ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChangeCaptcha} />
            }
          </Grid>
          <Grid item xs={12} className="wd-login-item" style={{margin: '10px 0'}}>
            <FormControlLabel
              control={<Checkbox checked={isTermsAccepted} onChange={handleTermsCheckboxChange} />}
              label={
                <span  style={{ fontSize: '1.1rem'}}>
                  I accept the <a href="https://info.supplytrace.org/privacy-policy/ " target="_blank" rel="noopener noreferrer">terms and conditions</a>
                </span>
              }
            />
        </Grid>
          {Object.keys(errorMessage).length > 0 && (
            <div style={{ color: 'red', marginBottom: '1rem' }}>
              {Object.keys(errorMessage).map((field, index) => (
                <div key={index}>
                  {field}: {errorMessage[field].join(', ')}
                </div>
              ))}
            </div>
          )}
            <Button
              className="wd-submit-btn-login"
              type="submit"
              startIcon={<LoginIcon />}
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
              disabled={!isCaptchaValidated || !isTermsAccepted}
            >
              Verify and Register
            </Button>
          </Grid>
        </Grid>
      </form>

      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mt-4">
        <p className="mb-0">Already have an account?</p>
        <MDBBtn outline className="mx-2" color="primary" onClick={setSignIn}>
          Sign in
        </MDBBtn>
      </div>
    </div>
  );
};

export default Register;
