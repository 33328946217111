import React from 'react';
import "./index.css";
const ColorLegend = () => {
  return (
    <div>
      <div className="legend">
        <div className="legend-item">
          <div className="color-block black"></div>
          <div>Black = Searched Entity</div>
        </div>
        <div className="legend-item">
          <div className="color-block red"></div>
          <div>Red = Potential Risk</div>
        </div>
        <div className="legend-item">
          <div className="color-block orange"></div>
          <div>Orange = Direct (Tier-1) Connection to Potential Risk</div>
        </div>
        <div className="legend-item">
          <div className="color-block yellow"></div>
          <div>Yellow = Indirect (Tier-2+) Connection to Potential Risk</div>
        </div>
        <div className="legend-item">
          <div className="color-block green"></div>
          <div>Green = No evidence based on current data and filters</div>
        </div>
      </div>

      <div className="learn-more">
        To learn more about potential risk, please visit our SupplyTrace Resources section.
      </div>
    </div>
  );
};

export default ColorLegend;
